
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { i18nService } from 'app/shared/services/i18n.service';

@Injectable()

export class HtmlService {

  constructor(public translate: TranslateService, public http: HttpClient, public i18nService: i18nService) { }

  getHtmlForInvitationLink(url, url2) {
    let html: any;



    html =
      "<br>" +
      "<br>" +
      "<div class='checkbox'>" +
      "<input type='checkbox' id='integrateVideo'>" +
      "<label for='integrateVideo'><span>" + this.i18nService.translateValue("Integrate videomeeting") + "?</span></label>" +
      "</div>" +
      "<br>" +
      "<br>" +
      "<img class='' src='assets/img/pages/overview.png' alt='Card image cap'>" +
      "<span class='badge badge-pill badge-round bg-light-primary font-small-1'>" + this.i18nService.translateValue("Link overview") + "</span>" + "<br>" +
      "<fieldset>" +
      "<div class='input-group'>" +
      "<input type='text' class='form-control  text-primary' id='urlInput' value='" + url + "' readonly='readonly'>" +
      "<div class='input-group-append'>" +
      "<button class='btn btn-primary' type='button' id='copyUrl' [ngbTooltip]='tipContent1' #x='ngbTooltip' triggers='manual'><i class='ft-copy mr-1'></i></button>" +
      "</div>" +
      "</div>" +
      "</fieldset>" +
      "<br>" +

      "<img class='' src='assets/img/pages/task.png' alt='Card image cap'>" +
      "<span class='badge badge-pill badge-round bg-light-primary font-small-1'>" + this.i18nService.translateValue("Link task") + "</span>" + "<br>" +
      "<fieldset>" +
      "<div class='input-group'>" +
      "<input type='text' class='form-control text-primary' id='url2Input' value='" + url2 + "' readonly='readonly'>" +
      "<div class='input-group-append'>" +
      "<button class='btn btn-primary' type='button' id='copyDeepUrl'><i class='ft-copy mr-1'></i></button>" +
      "</div>" +
      "</div>" +
      "</fieldset>"

    return html
  }

  getHtmlForRandomImageResult(imageSource) {
    let html: any;
    html =
      "<img class='d-flex align-content-start flex-wrap' src='" + imageSource + "' alt='Image'>";
    return html
  }

  getHtmlForJistisButton(id) {
    let url = window.location.host;
    let html: any;
    if (url == "localhost:4200") {
      url = "http://localhost:4200";
    }
    html =
      "<button class='btn btn-primary' type='button' href='" + url + "/" + id + "''>" + this.i18nService.translateValue("Open Jitsi") + "</button>"
    return html;
  }

  getHTMLforInitTask() {
    let description = `
    <div class="row">
    <div class="col-6">
      <section class="bdg-sect">
      </section>
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">Header
          </h4>
        </div>
        <div class="card-content">
          <div class="card-body">
            Content
          </div>
        </div>
      </div>
    </div>
    <div class="col-6">
    </div>
  </div>
    `

    return description;
  }



}