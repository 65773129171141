<div class="card overflow-hidden">
    <div class="card-content">
        <div class="card-body">
            <h4 class="card-title">{{task.taskTitle}}</h4><br>
            <div class="overflow-hidden max-heigh img-fluid text-left cursor-text"
                [innerHtml]="determineTaskMessage(task.taskMessage) | safe: 'html'">
            </div>


               <!-- Start Videocall -->
               <button (click)="cardFunctionService.openVideoCall(task)" type="button"
               class=" btn card-margin btn-social btn-outline-google" *ngIf="task.messageType == 'videocall'"><i
                 class="icon-camcorder"></i>Videocall starten</button>
             <!-- End Videocall -->

             <!-- Start Videocall -->
             <button (click)="cardFunctionService.writeMail(task, list, board)" type="button"
               class=" btn card-margin btn-social btn-outline-facebook" *ngIf="task.messageType == 'contact'"><i
                 class="icon-envelope-letter"></i>Nachricht
               schreiben</button>
             <!-- End Videocall -->

             <!-- Start ToolButton -->
             <button (click)="cardFunctionService.openLink(task.url)" type="button"
               class="text-left btn card-margin btn-social btn-outline-soundcloud"
               *ngIf="task.messageType == 'tool'"><i class="ft-play"></i>{{task.additionalField1}} öffnen</button>
             <!-- End ToolButton -->

             <!-- Start Image, Link, Video Prview -->
             <div
               *ngIf="task.messageType === 'picture' || task.messageType === 'link'  || task.messageType === 'video'"
               class="d-flex  overflow-hidden rounded my-3">
               <img [src]="task.imageUrl" alt="ToDo Pic" class="img-fluid"
                 *ngIf="task.messageType === 'link' && task.imageUrl != null && task.imageUrl != ''"
                 (click)="cardFunctionService.openLink(task.url)">
               <div *ngIf="task.messageType === 'picture'">
                 <img [src]="file.url" alt="ToDo Pic" class="img-fluid" *ngFor='let file of task.files'
                   (click)="cardFunctionService.openLink(file.url)">
               </div>
               <div *ngIf="task.messageType === 'video'" class="card-margin img-fluid"
                 [innerHtml]="cardFunctionService.getVideoUrl(task)   | safe: 'html'"></div>
             </div>
             <!-- End Image, Link, Video Prview -->

             <!-- Start Files -->
             <div class="card-content overflow-hidden" *ngIf="task.messageType === 'file'">
               <div class="card-body">
                 <div class="media pt-0 pb-2" *ngFor='let file of task.files'>
                   <!--  <img class="mr-3 avatar rounded" src="assets/img/portrait/small/avatar-s-20.png" alt="Avatar"
                     width="35"> -->
                   <div class="media-body  overflow-hidden">
                     <h5 class="font-medium-1 mb-0 text-left cursor-pointer" (click)="cardFunctionService.openLink(file.url)">
                       {{file.name}}</h5>
                     <p class="grey font-small-3 m-0 text-left">Größe: {{ file.size/1024/1024 | number:'.2' }} MB
                       Dateityp:
                       {{file.type}}</p>
                   </div>
                   <button type="button" class="btn btn-primary" (click)="cardFunctionService.downloadFile(file.url)">
                     <i class="ft-download"></i>
                   </button>
                 </div>
               </div>
             </div>
             <!-- End Files -->

             <!-- Start Zeitslots -->

             <!--  <mwl-calendar-day-view   [events]="events" [refresh]="refresh"
             (eventClicked)="handleEvent('Clicked', $event.event)" (eventTimesChanged)="eventTimesChanged($event)" >
           </mwl-calendar-day-view> -->

             <!-- Ende Zeitslots -->

             <div class="justify-content-center mb-2 overflow-hidden"><a
                 *ngIf="task.messageType == 'link' || task.messageType == 'tool'"
                 class="primary card-margin  cursor-text" (click)="cardFunctionService.openLink(task.url)">{{task.url}}</a></div>
            
        </div>
    </div>
</div>