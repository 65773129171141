import { Injectable } from '@angular/core';
import { Task } from './taskboard.model';
import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { Board } from '../my-taskboards/board.model';
import { StorageService } from 'app/shared/services/storage.service';
import { GeneralDataService } from 'app/shared/firebase-services/generalData.service'; 3
import * as alertFunctions from '../../../shared/data/sweet-alerts';
import { List } from './taskboardlist.model';
import { PromiseService } from 'app/shared/services/promise.service';
import { User } from 'firebase';
import { MetatagHttpService } from 'app/shared/http-services/metatag-http.service';
import { UploadFile } from 'app/shared/models/uploadfile.model';
import { ImageService } from 'app/shared/services/image.service';
import { UploadService } from 'app/shared/services/upload.service';
import { VideoPortalService } from 'app/shared/services/videoportal.service';
import { ArrayService } from 'app/shared/services/array.service';
import { UserData } from 'app/shared/models/user.model';
import { UserProfile } from 'app/shared/models/userProfile.model';
import { Md5 } from 'ts-md5/dist/md5';
import { StringService } from 'app/shared/services/string.service';
import { ToastService } from 'app/shared/services/toast.service';



@Injectable()
export class TaskBoardService {

  user: any;
  board: Board;
  public boards: Board[];

  public tasks: Task[] = [];

  constructor(public storageService: StorageService, public generalDataService: GeneralDataService, public promiseService: PromiseService,
    public metaTagService: MetatagHttpService, public imageService: ImageService, public uploadService: UploadService, public arrayService: ArrayService,
    public stringService: StringService, public toastService: ToastService) {
    this.user = this.storageService.getUser();
  }

  //public tasks: Task[] = [];


  addNewTask(title: string, message: string, type: string) {

    let badgeClass = 'primary';

    if (type === 'Marketing') {
      badgeClass = 'primary'
    }
    else if (type === 'UI-Designing') {
      badgeClass = 'warning'
    }
    else if (type === 'Developing') {
      badgeClass = 'success'
    }
    else if (type === 'Management') {
      badgeClass = 'info'
    }


    let task: Task = {
      orderNo: Math.round(Math.random() * 10000000000),
      taskId: Math.round(Math.random() * 10000000000),
      taskTitle: title,
      taskMessage: message,
      createdOn: new Date(),
      createdBy: 'Elizabeth Elliott',
      assignedTo: ['assets/img/portrait/small/avatar-s-4.png'],
      assignedToImage: [this.user.photoURL],
      badgeClass: badgeClass,
      messageType: 'text',
      messageCount: '',
      linkCount: '',
      isUserImg: true,
      status: 'new',
      url: "",
      imageUrl: "",
      isVisible: true,
      additionalField1: "",
      events: "",
      eventStartingTime: "",
      eventEndingTime: "",
      settings: null,
      isCollapsed: false,
      isCollapsedForView: false
    }
    this.tasks.unshift(task);
    return of(this.tasks.slice()).pipe(delay(100));

  }

  updateBoard(title: string, message: string, url: string, type: string, board) {
    board.boardTitle = title;
    board.boardMessage = message;
    //board.lists = null;
    board.boardLink = url;

    if (board.boardId == null && board.id != null) {
      board.boardId = board.id;
    }

    this.generalDataService.updateData("boards", board.boardId, board, false)
    return board;
  }

  updateList(list: List, board) {
    const clone = { ...list };
    clone.tasks = [];
    this.generalDataService.updateData('/boards/' + board.boardId + '/lists', clone.id, clone, false).then(res => {

    });

  }

  addNewBoard(title: string, message: string, link: string, type: string) {
    return new Promise<any>((resolve, reject) => {
      let badgeClass = 'primary';

      if (type === 'Marketing') {
        badgeClass = 'primary'
      }
      else if (type === 'UI-Designing') {
        badgeClass = 'warning'
      }
      else if (type === 'Developing') {
        badgeClass = 'success'
      }
      else if (type === 'Management') {
        badgeClass = 'info'
      }

      if (this.user == null || this.user == '') {
        this.user = new UserData();
        this.user.id = 'Guest'
      }

      let board: Board = {
        boardId: null,
        boardTitle: title,
        boardMessage: message,
        createdBy: this.user.id,
        assignedTo: [this.user.id],
        status: 'created',
        createdOn: new Date(),
        badgeClass: badgeClass,
        boardType: type,
        boardLink: link,
        lists: [],
        boardBgImageUrl: null,
        settings: this.getSettings()
      }


      this.generalDataService.setData("boards", board, false).then(res => {
        board.boardId = res.id;
        resolve(board)
      })

    })
    //return board;
  }

  addNewList(title: string, icon: string, boardId: string, orderNo: number) {
    return new Promise<any>((resolve, reject) => {
      icon = 'ft-plus';

      if (this.user == null || this.user == '') {
        this.user = new UserData();
        this.user.id = 'Guest'
      }

      let list: List = {
        orderNo: orderNo,
        id: null,
        title: title,
        createdBy: this.user.id,
        assignedTo: [this.user.id],
        status: 'created',
        createdOn: new Date(),
        icon: icon,
        visible: true,
        tasks: [],
        settings: this.getListSettings(),
        isListNameToEdit: false
      }
      this.generalDataService.setData("boards/" + boardId + '/lists', list, false).then(res => {
        list.id = res.id;
        //list.tasks = null;
        this.generalDataService.updateData("boards/" + boardId + '/lists', res.id, list, false).then(res => {
          resolve(list)
        }).catch(error => {
          reject(error)
        })
      }).catch(error => {
        reject(error)
      })
    })

  }

  getBoardsForUser(userId) {
    return new Promise<any>((resolve, reject) => {
      let boards: Board[] = [];
      this.generalDataService.getDatasFor('/boards', 'createdBy', '==', userId, false).then(res =>
        res.map(a => {
          let data = a.payload.doc.data() as Board;
          data.boardId = a.payload.doc.id;
          boards.push(data);
        })
      )
    })
  }

  getBoardsForUser2(userId) {

    return this.generalDataService.getMappedDataFor('/boards', 'createdBy', '==', userId, false, 'Board');

  }

  addNewCard(title: string, message: string, link: string, type: string, board: Board, list: List, files: UploadFile[], email: string, additionalField1: string) {
    return new Promise<any>((resolve, reject) => {
      let photourl: any;
      let length;
      let tmpMessage: any;

      if (this.user == null || this.user == '') {
        this.user = new UserData();
        this.user.profile = new UserProfile()
        this.user.id = 'Guest'
        photourl = this.imageService.getRandomImage('Guest' + board.boardId, 200, 200);
        this.user.profile.photoURL = photourl;
      } else {
        photourl = this.user.profile.photoURL;
      }

      if (list.tasks == null || list.tasks == []) {
        length = 0;
      } else {
        length = list.tasks.length;
      }

      let task: Task = {
        orderNo: length,
        taskId: null,
        taskTitle: title,
        taskMessage: message,
        createdBy: this.user.id,
        assignedTo: [this.user.id],
        assignedToImage: [photourl],
        status: 'created',
        createdOn: new Date(),
        badgeClass: type,
        messageType: type,
        messageCount: '0',
        linkCount: '0',
        isUserImg: true,
        url: "",
        imageUrl: "",
        isVisible: true,
        additionalField1: additionalField1,
        events: "",
        eventStartingTime: "",
        eventEndingTime: "",
        settings: null,
        isCollapsed: false,
        isCollapsedForView: false
      }

      if (email != null && email != "") {
        task.url = email;
      } else {
        task.url = link;
      }

      this.getMetaData(link, task).then(taskRes => {
        task = taskRes;
        if (type == 'html') {
          tmpMessage = task.taskMessage;
          task.taskMessage = "";
        }
        this.generalDataService.setData("/boards/" + board.boardId + '/lists/' + list.id + '/tasks', task, false).then(res => {
          task.taskId = res.id;
          if (type == 'html') {
            task.taskMessage = tmpMessage;
          }
          this.handleTaskData(list.id, task, files, board.boardId).then(resTask => {
            this.generalDataService.updateData("/boards/" + board.boardId + '/lists/' + list.id + '/tasks', resTask.taskId, resTask, false).then(res => {

            });
            resolve(task)
          })
        }).catch(error => {
          reject(error)
        })
      }).catch(error => {
        reject(error)
      })
    })
    //return board;
  }

  updateTask(task, board, list) {
    return new Promise<any>((resolve, reject) => {
      this.generalDataService.updateData("/boards/" + board.boardId + '/lists/' + list.id + '/tasks', task.taskId, task, false).then(res => {
        resolve(res)
      }).catch(error => {
        reject(error)
      });
    });
  }

  handleTaskData(id, task, files, boardId) {
    return new Promise<any>((resolve, reject) => {
      if (task.messageType == 'picture' || task.messageType == 'html') {
        this.uploadService.uploadImages('boards', boardId, files, false).then(filesRes => {
          task.files = filesRes;
          if (task.messageType == 'html') {
            for (let index = 0; index < files.length; index++) {
              const element = files[index];
              let tmpmessage = task.tmpmessage;
              if (element.base64 != "") {
                task.taskMessage = this.stringService.replace(task.taskMessage, element.base64, element.url);
              }
              element.base64 = '';
            }
          }
          resolve(task)
        })
      } else if (task.messageType == 'video') {
        resolve(task)
      } else if (task.messageType == 'file') {
        this.uploadService.uploadFiles('boards', boardId, files, true).then(filesRes => {
          task.files = filesRes;
          resolve(task)
        })
      } else {
        resolve(task)
      }

    })
  }

  updateCard(title: string, message: string, link: string, type: string, board: Board) {
    board.boardTitle = title;
    board.boardMessage = message;

    let boardToSave = { ...board }
    boardToSave.lists = null;

    this.generalDataService.updateData("boards", board.boardId, boardToSave, false)
    return board;
  }

  deleteCard(board: Board, list: List, task: Task, taskId: string, deleteFile: boolean) {
    return new Promise<any>((resolve, reject) => {
      this.generalDataService.deleteData("/boards/" + board.boardId + '/lists/' + list.id + '/tasks', taskId, false).then(res => {
        if (deleteFile == true) {
          this.uploadService.deleteFiles(task)
        }
        resolve(true)
      }).catch(error => {
        console.log("Löschen der Karte konnte nicht ausgeührt werden. Bitte erneut versuchen. Error:" + error)
        reject(error)
      })
    });
  }

  initTaskboard(boardId) {
    this.promiseService.init()
    let board;
    return new Promise<any>((resolve, reject) => {
      this.generalDataService.getData('/boards', boardId, false).then(res => {
        board = res.payload.data() as Board;
        board.lists = [];
        board.boardId = res.payload.id;
        this.generalDataService.getAllDatasValueChange('/boards/' + boardId + '/lists', false).then(async datas => {
          datas = this.arrayService.sortBy(datas, 'orderNo')
          for (let index = 0; index < datas.length; index++) {
            const element = datas[index];
            element.tasks = [];
            let tasks = await this.generalDataService.getAllDatasValueChange('/boards/' + boardId + '/lists/' + element.id + "/tasks", false);
            tasks = this.arrayService.sortBy(tasks, 'orderNo')
            tasks = this.handleEventDates(tasks)
            element.tasks = tasks;
            board.lists.push(element)
          }
          resolve(board)
        });
      }).catch(error => {
        this.toastService.show("Error", "Error", error)
      });
    })
  }

  handleEventDates(tasks) {
    let updatedTasks = [];
    let updatedTask: any;
    for (let index = 0; index < tasks.length; index++) {
      const elementTask = tasks[index];

      if (elementTask.events != null && elementTask.events != "") {
        for (let index2 = 0; index2 < elementTask.events.length; index2++) {
          const elementEvent = elementTask.events[index2];
          elementEvent.start = new Date(elementEvent.start)
          elementEvent.end = new Date(elementEvent.end)
        }
      }
      updatedTasks.push(elementTask);
    }
    return updatedTasks;
  }

  getMetaData(url, task) {
    return new Promise<any>((resolve, reject) => {
      if (task.messageType != "link" && task.messageType != "video") {
        resolve(task)
      }

      this.metaTagService.getCrawlerByElements(url, "article", "iframe", "p", "").then(res => {


        if ((task.taskTitle == "" || task.taskTitle == null) && res != null && res.title != null) {
          //console.log(res, unescape(encodeURIComponent(res.title)));
          task.taskTitle = res.title;
          //task.taskTitle = res.title;
        }

        if ((task.taskMessage == "" || task.taskMessage == null) && res != null && res.description != null) {
          task.taskMessage = res.description;
          //task.taskMessage = unescape(encodeURIComponent(res.description))
        }

        if ((task.imageUrl == "" || task.imageUrl == null) && res != null && res.imageUrl != null) {
          task.imageUrl = res.imageUrl;
        }


        resolve(task)
      })
    })

  }

  getTask(listId, taskId, boardId) {
    return new Promise<any>((resolve, reject) => {
      this.generalDataService.getData('/boards/' + boardId + '/lists/' + listId + '/tasks/', taskId, false).then(res => {
        resolve(res.payload.data() as Task)
      });
    });
  }

  getList(listId, boardId) {
    return new Promise<any>((resolve, reject) => {
      this.generalDataService.getData('/boards/' + boardId + '/lists/', listId, false).then(res => {
        resolve(res.payload.data() as List)
      });
    });
  }

  getSettings() {
    return {
      isPWset: false,
      cardColor: '#faf0e6',
      listPadding: 'col-xl-3 col-sm-6 col-12',
      pwHash: null, userAuthority: 'FORBIDDEN',
      linkAuthority: 'FORBIDDEN',
      adminHash: Md5.hashStr(new Date().toString() + this.user.createdBy + 'Eine kleine Mickey Mouse'),
      listHeaderColor: "#faf0e6",
      headerColor: "#faf0e6",
      headerTextColor: "#000000",
      textColor: '#000000',
      listTextColor: '#000000',
      bgColor: "",
      headerBGImage: null,
      seperateCardTypeColors: true
    }
  }

  getListSettings() {
    return {
      col: 3
    }
  }

  updateTaskData(id: string, list: List, tasks, board, task?: Task,) {
    return new Promise<any>((resolve, reject) => {
      try {
        let currentTask: Task;

        if (task) {
          currentTask = task;
        }
        else {
          currentTask = tasks.find(x => x.taskId === +id);
        }

        let index = tasks.indexOf(currentTask);
        currentTask.status = status;
        tasks.splice(index, 1, currentTask);
        tasks = [...tasks];
        this.updateTask(currentTask, board, list).then(res => {
          resolve(res)
        }).catch(error => {
          reject(error)
        });
      } catch (error) {
        reject(error);
      }
    })
  }

  updateListCards(list, board) {
    for (let index = 0; index < list.tasks.length; index++) {
      const element = list.tasks[index];
      element.orderNo = index;
      this.generalDataService.updateData('/boards/' + board.boardId + '/lists/' + list.id + '/tasks', element.id, element, false).then(res => {

      }).catch(error => {
        this.toastService.show("Error", "Fehler", "Beim Verschieben der Karte ist ein Fehler aufgetreten!")
      });
      //this.taskBoardService.updateList()
    }
  }

  updateListCardsWithPromise(list, board) {
    return new Promise<any>((resolve, reject) => {
      let promises = [];
      for (let index = 0; index < list.tasks.length; index++) {
        const element = list.tasks[index];
        element.orderNo = index;
        promises.push(this.generalDataService.updateData('/boards/' + board.boardId + '/lists/' + list.id + '/tasks', element.id, element, false))
      }
      Promise.all(promises).then(res => {

        resolve(res)
      })
    })
  }



}


