import { Router, ActivatedRoute } from '@angular/router';
import { ChangeDetectorRef, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as alertFunctions from '../../shared/data/sweet-alerts';
import { Reward } from '../models/reward.model';
import 'quill-emoji/dist/quill-emoji.js'
import { GeneralDataService } from '../firebase-services/generalData.service';
import Quill from 'quill'
import * as QuillNamespace from 'quill';
import { ScriptService } from './script.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
//import { LayoutModalComponent } from '../modals/layout/layout-modal.component';
import Delta from 'quill-delta'
//import htmlEditButton from "quill-html-edit-button";
const Base = Quill.import('blots/embed');
//const html = Quill.register("modules/htmlEditButton", htmlEditButton);


export const ngQuill: any = QuillNamespace;


@Injectable()
export class QuillService {

  quill:any;
  selection:any;
  index:any;
  length:any;

  currentContentHTML:any;


  constructor(private translate: TranslateService, public generalDataService: GeneralDataService, public scriptService: ScriptService, public modalService: NgbModal) {
    this.scriptService.load("https://unpkg.com/quill-html-edit-button@2.1.0/dist/quill.htmlEditButton.min.js")
    //this.scriptService.load("https://github.com/koffeinfrei/quill-task-list/blob/master/task_list.js")
    this.index = 0;
    this.length = 0;
    const icons = Quill.import('ui/icons');
    icons['undo'] = '<svg viewbox="0 0 18 18"><polygon class="ql-fill ql-stroke" points="6 10 4 12 2 10 6 10"></polygon>' +
      '<path class="ql-stroke" d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9"></path></svg>';
    icons['redo'] = '<svg viewbox="0 0 18 18"><polygon class="ql-fill ql-stroke" points="12 10 14 12 16 10 12 10"></polygon>' +
      '<path class="ql-stroke" d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5"></path></svg>';
  
  }

  init(quill, cdr){
    this.quill = quill;
    //this.addHTMLButton(cdr)
  }

  setQuill(quill){
    //this.quill = quill;
  }

  handleChange(quill){
      this.index = quill.range.index;
      this.length = quill.range.length;
  }

  setIndex(index){
    this.index = index;
  }

  /* addHTMLButton(cdr: ChangeDetectorRef){
    var button = document.getElementsByClassName('.ql-templates');
    document.querySelector('.ql-templates').innerHTML = "<i class='ft-search warning cursor-pointer'></i>"

    var customButton = document.querySelector('.ql-templates');
    let that = this;
    customButton.addEventListener('click', function() {
      that.addLayout(cdr)
    });    
  }
 */
  getContent(){
    console.log("Clicpboard",this.quill.getContents(),this.quill.root.innerHTML)
    return this.quill.root.innerHTML;
  }

  changeContent(html){

  }


  quillAddHtml(html, cdr: ChangeDetectorRef){
    this.currentContentHTML = html;
    
    //this.quill.clipboard.dangerouslyPasteHTML(this.index, "<div class='card'><div class='card-content'>Hier steht der Tesxt<div class='card-body'>");
    //this.quill.insertEmbed(10, 'text', "<div class='card'><div class='card-content'>Hier steht der Tesxt<div class='card-body'>");
    //console.log("Nach laden", this.quill.clipboard,this.quill.getContents(),this.quill.root.innerHTML)

    /* const clipboard = this.quill.getModule('clipboard')
    const pastedDelta = clipboard.convert({html: html, text: ''})
    console.log(pastedDelta)
    const delta = new Delta()
                      .retain(this.index)
                      .delete(this.length)
                      .concat(pastedDelta);

    this.quill.clipboard.dangerouslyPasteHTML(this.index, delta);
    const restored = delta.compose(delta);
    console.log(delta, html)
    this.currentContent = delta */
    //this.quill.insertText(0,html)

    let el = (document.getElementById("textareaquill") as HTMLTextAreaElement)
    console.log("element", el, this.index, this. length)

    


    el.value = html;
    cdr.markForCheck();
  }

  getCurrentContent(){
    //console.log("ContenT", this.currentContentHTML)
    return this.currentContentHTML
  }

  quillAddText(index, text){
    this.quill.insertText(index, text);
  }



  getEditorOptions() {
    let editorOptions;
    return editorOptions = {
      'emoji-shortname': false,
      'emoji-textarea': false,
      'emoji-toolbar': true,
      //syntax: true,
      //imageDrop: true,
      
      toolbar: this.getToolbar(),
      //'task-list': true
      //htmlEditButton: {}
      //'task-list': true
      
     
    };
  }


  getToolbar() {
    let toolbar = {container:[],
                   handlers: {}};
    return toolbar = {container:[  
      // [{ 'HTML': ['Liste', 'Tabelle'] }],
      //['templates'],
      ['bold', 'italic', 'underline', 'strike'],     // toggled buttons
      ['undo', 'redo'],
      ['blockquote', 'code-block'],
      [{ 'header': 1 }, { 'header': 2 }],               // custom button values
      [{ 'list': 'ordered' }, { 'list': 'bullet' },  { 'list': 'check' }],

      [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
      [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
      //[{ 'direction': 'rtl' }],                         // text direction

      [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

      [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
      [{ 'font': [] }],
      [{ 'align': [] }],

      ['clean'],                                         // remove formatting button

      ['link', 'image', 'video'],
      ['emoji'], 
                           // link and image, video
    ],
    handlers: {
      'undo': () => this.quill.history.undo(),
      'redo': () => this.quill.history.redo(),
    }
  }
  }

 /*  addLayout(cdr){
    const modalRef = this.modalService.open(LayoutModalComponent, {size: 'lg'});
    
    modalRef.result.then((result) => {
     this.quillAddHtml(result.value, cdr)
    }).catch((error) => {
      console.log(error);
    });
} */

initPicker() {
      /* const placeholderPickerItems = Array.prototype.slice.call(document.querySelectorAll('.ql-HTML .ql-picker-item'));


    placeholderPickerItems.forEach(item => item.textContent = item.dataset.value);

    document.querySelector('.ql-HTML .ql-picker-label').innerHTML
        = 'HTML' + document.querySelector('.ql-HTML .ql-picker-label').innerHTML;

        var customButton = document.querySelector('.ql-HTML');
        customButton.addEventListener('click', function() {
          console.log('Clicked!');
        });   */  
}

}
