import { Injectable } from '@angular/core';
import { StorageService } from 'app/shared/services/storage.service';
import { Board } from '../my-taskboards/board.model';

@Injectable({
  providedIn: 'root'
})
export class TaskboardAuthService {

  user:any;

  constructor(public storageService:StorageService) { 
    this.user = this.storageService.getUser();
  }

  isForbidden(board, ah) {
    if ((this.user == null || this.user == "") && board.settings.linkAuthority == 'FORBIDDEN' && board.createdBy != this.user.id && ah != board.settings.adminHash) {
      return true;
    }
    if ((this.user != null) && board.settings.userAuthority == 'FORBIDDEN' && board.createdBy != this.user.id && ah != board.settings.adminHash) {
      return true;
    }

    /*    if((this.user == null || this.user == "") && this.ah == board.settings.adminHash){
         return true;
       } */
    return false;
  }

  isAuthForChange(board:Board, ah, neededAuthority?: string) {
    //Erste Bedingung: Ich bin der Ersteller
    //Ruwen ID =  this.user.id == 's0jYeiwsbDba9IMiSNEMTUyd9Tf2')
    if (this.user != null && this.user.id == board.createdBy) {
      return true;
    }
    // Zweite Bedingung: Ich habe den Generallschlüssel und bin registriert
    if (board.settings != null && (this.user == null || this.user == '') && ah == board.settings.adminHash) {
      return true;
    }
    if (board.settings != null && this.user != null && this.user != '' && ah == board.settings.adminHash) {
      return true;
    }
    //Sonderbedingung: Es wird keine Berechtigung in die Methode gebebn
    if (neededAuthority == null || neededAuthority == '') {
      return false;
    }


    // Dritte Bedingung: Berechtigung für registrierte Benutzer registrierte Benutzer
    if (this.user != null && this.user != 'null' && this.user != "" && (this.isLinkAuthority(board, neededAuthority) == true || this.isUserAuthority(board, neededAuthority)) == true) {
      return true;
    }
    // Dritte Bedingung: Berechtigung für jeden mit Link Benutzer
    if (this.isLinkAuthority(board, neededAuthority) == true) {
      return true;
    }
    return false;

  }

  isLinkAuthority(board:Board,neededAuthority?: string) {
    if (board.settings.linkAuthority == 'FORBIDDEN') {
      return false;
    }

    if (board.settings.linkAuthority == 'VIEW' && neededAuthority == 'VIEW') {
      return true;
    }

    if (board.settings.linkAuthority == 'COMMENT' && (neededAuthority == 'VIEW' || neededAuthority == 'COMMENT')) {
      return true;
    }

    if (board.settings.linkAuthority == 'EDIT' && (neededAuthority == 'VIEW' || neededAuthority == 'EDIT')) {
      return true;
    }
  }

  isUserAuthority(board:Board,neededAuthority?: string) {
    if ((this.user != null) && board.settings.userAuthority == 'FORBIDDEN') {
      return false;
    }

    if ((this.user != null) && board.settings.userAuthority == 'VIEW' && neededAuthority == 'VIEW') {
      return true;
    }

    if ((this.user != null) && board.settings.userAuthority == 'COMMENT' && (neededAuthority == 'VIEW' || neededAuthority == 'COMMENT')) {
      return true;
    }

    if ((this.user != null) && board.settings.userAuthority == 'EDIT' && (neededAuthority == 'VIEW' || neededAuthority == 'EDIT')) {
      return true;
    }

  }

}
