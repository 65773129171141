import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import 'rxjs/add/operator/toPromise';
import { environment } from 'environments/environment';

import { HttpClientModule, HttpClient, HttpHeaders , HttpParams} from '@angular/common/http';

@Injectable()
export class MyPhpHttpService {

  constructor(public http: HttpClient) { }

  getData(url) {
    return new Promise<any>((resolve, reject) => {
      //this.http.post(environment.api_endpoint + url, {  }).subscribe(
      this.http.post('https://edugle.digil.me/api/crawler/' + url, {  }).subscribe(
        result => {
         resolve(result);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  updateLink(url, id, parentid, changeddate, httpstatus, contenttype ) {
    return new Promise<any>((resolve, reject) => {
      console.log(url, id, parentid, changeddate, httpstatus, contenttype)
      //this.http.post(environment.api_endpoint + url, {  }).subscribe(
      this.http.post('https://edugle.digil.me/api/crawler/' + url, { 'id': id, 'parentid': parentid, 'changedate': changeddate, 'httpstatus': httpstatus, 'contenttype': contenttype }).subscribe(
        result => {
         resolve(result);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  storeLink(url, link, host, sourceurl, httpstatus, contenttype ) {
    return new Promise<any>((resolve, reject) => {
      //this.http.post(environment.api_endpoint + url, {  }).subscribe(
      this.http.post('https://edugle.digil.me/api/crawler/' + url, { 'link': link, 'host': host, 'sourceurl': sourceurl, 'httpstatus': httpstatus, 'contenttype': contenttype }).subscribe(
        result => {
         resolve(result);
        },
        error => {
          reject(error);
        }
      );
    });
  }

}
