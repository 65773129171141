export class Reward{
    type: string;
    kind: string;
    icon: string;
    title: string;
    description:string;
    link: string
    createdAt: number;
    hasButton:boolean;
    btnTitle:string;
    btnLink:string;
 }
    