
import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { GeneralDataService } from '../firebase-services/generalData.service';
import { MyPhpHttpService } from '../http-services/myphp-http.service';
import { EduScore } from '../models/eduscore.model';
import { Rating } from '../models/rating.model';
import { SearchListItem } from '../models/searchlistitem.model';
import { ArrayService } from './../services/array.service';
import { StringService } from './../services/string.service';


//https://firebase.google.com/docs/auth/web/manage-users

@Injectable()
export class MetaTagZumDeService {

  constructor(public generalDataService: GeneralDataService, public stringService: StringService, public arrayService: ArrayService, public phpService: MyPhpHttpService) {

  }


  convertItem(metaTags, url, inputSource, additionalSearchString) {
    console.log("Metatags:", metaTags)

    if (metaTags == null) {
      return;
    }

    let searchItem = new SearchListItem();
    searchItem.url = url;

    searchItem.videoUrls = this.getVideoUrls(metaTags.iframe)
    searchItem.title = this.determineTitle(metaTags)
    searchItem.description = this.determineDescription(metaTags);
    searchItem.type = this.determineType(metaTags, url, metaTags.host);
    searchItem.eduScore = new EduScore();
    searchItem.imageUrl = "https://www.edutags.de/themes/edutheme/logo.png";
    searchItem.inputSource = inputSource;
    searchItem.name = this.determineTitle(metaTags)
    searchItem.searchDescription = this.determineSearchDescription(metaTags);
    searchItem.searchString = this.determineSearchString(metaTags);
    if(additionalSearchString != null && additionalSearchString != ""){
      searchItem.searchString = searchItem.searchString + " " + additionalSearchString;
    }
    searchItem.status = 'PUBLISHED';
    searchItem.createdAt = new Date();
    searchItem.contact = this.determineContact();
    searchItem.contactType = this.determineContactType();
    searchItem.countOfRatings = 0;
    searchItem.currentRating = 0;
    searchItem.host = metaTags.host;
    searchItem.crawlerBlocked = this.determineIsCralwerBlocked(metaTags);
    searchItem.categories = this.determineCategories(metaTags);

    console.log("Search-Item:", searchItem);
    //this.handlePHPSave(metaTags)

    return searchItem;
  }
  
  handlePHPSave(metaTags){
    if(metaTags.p != null && metaTags.p[0] != null && metaTags.p[0].text != null)
    this.phpService.storeLink('storelink.php', metaTags.p[0].text, metaTags.host, metaTags.url, "", "" ).then(res => {
      console.log("PHP Store:", res)
    })
  }


  determineCategories(metatag){
    let categories = [];

    for (let index = 0; index < metatag.a.length; index++) {
      const element = metatag.a[index];
      if(element.attributes != null && element.attributes.href != null &&  this.stringService.substringExists(element.attributes.href, "/wiki/Kategorie:")){ 
          categories = this.addCategory(categories, element.text, element.text)
      }
    }

    categories = this.arrayService.removeDublicates(categories, it => it.value);

    return categories; 
  }

  determineTitle(metaTags){
    if(metaTags.title != null &&  metaTags.title != ""){
      return  metaTags.title;
    } else if(metaTags.p != null && metaTags.p[0] != null && metaTags.p[0].text != null){
      return metaTags.host + ' - '  + metaTags.p[0].text;
    } else {
      return metaTags.host
    }
  }

  determineDescription(metaTags) {
    if (metaTags.description != null && metaTags.description != '') {
      return metaTags.description;
    } else {
      if (metaTags.p != null && metaTags.p[0] != null && metaTags.p[0] != [] && metaTags.p[0].text != null && metaTags.p[0].text != "") {
        return metaTags.p[0].text;
      } else {
        return "Die Seite hat " + metaTags.url + " hat keine Meta-Tags definiert. Aber trotzdem gute Inhalte. Wir werden versuchen mit den Seitenbetreibern in Kontakt zu treten, um die Beschreibung zu verbessern."
      }
    }
  }

  determineSearchDescription(metaTags) {
    if (metaTags.description != null && metaTags.description != '') {
      return metaTags.description;
    } else {
      if (metaTags.p != null && metaTags.p[0] != null && metaTags.p[0] != [] && metaTags.p[0].text != null && metaTags.p[0].text != "") {
        return metaTags.p[0].text;
      } else {
        return "Die Seite hat " + metaTags.url + " hat keine Meta-Tags definiert. Aber trotzdem gute Inhalte. Wir werden versuchen mit den Seitenbetreibern in Kontakt zu treten, um die Beschreibung zu verbessern."
      }
    }
  }


  getVideoUrls(iframes) {
    let urls = [];
    if (iframes != null) {
      for (let index = 0; index < iframes.length; index++) {
        const element = iframes[index];
        if (element.attributes.class == "video-embed" || element.attributes.allowfullscreen != null) {
          urls.push(element.attributes.src);
        }
      }
      return urls;
    }
  }

  determineType(metaTags, url, host) {
    if (this.isFile(url)) {
      return 'File';
    } else if (this.stringService.substringExists(host, 'twitter') || this.stringService.substringExists(host, 'facebook') || this.stringService.substringExists(host, 'linkedin') || this.stringService.substringExists(host, 'xing')) {
      return 'Account';
    } else if (this.stringService.substringExists(host, 'youtube') && this.stringService.substringExists(host, 'channel')) {
      return 'Account';
    } else if (this.stringService.substringExists(host, 'youtube') || this.stringService.substringExists(host, 'vimeo')) {
      return 'Video';
    } else if (this.stringService.substringExists(host, 'youtube')) {
      return 'Video';
    } else {
      return 'Entry';
    }
  }

  isFile(url) {
    if (url == null || url == '') {
      return false;
    }

    var lastPart = url.substr(url.lastIndexOf('.') + 1);

    if (lastPart === "pdf" || lastPart === "doc" || lastPart === "docx" || lastPart === "xls") {
      return true;
    } else {
      return false;
    }
  }

  determineContact() {
    return "";
  }

  determineContactType() {
    return "";
  }

  determineSearchString(metaTags) {
    if (this.stringService.substringExists(metaTags.pstring, "Cloudflare")) {
      return metaTags.pstring = metaTags.title + ' ' + metaTags.description;
    } else {
      return metaTags.pstring;
    }
  }

  determineIsCralwerBlocked(metaTags) {
    if (this.stringService.substringExists(metaTags.pstring, "Cloudflare")) {
      return true;
    } else {
      return false;
    }
  }

  getHost(url) {
    var el = document.createElement('a');
    el.href = url;
    console.log(el)
    return el.hostname;
  }


  addCategory(categories, value, display) {
    categories.push({
      'display': display,
      'value': value
    });
    return categories;
  }
}
