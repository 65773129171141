import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { JwtService } from './jwt.service';
import { StringService } from './string.service';
import { ErrorService } from './error.service';
import { DomSanitizer, SafeHtml, SafeResourceUrl } from '@angular/platform-browser';
import { ScriptService } from './script.service';

declare const miroBoardsPicker: any;


@Injectable()
export class MiroService {


  constructor(public http: HttpClient, public jwtService: JwtService, public stringService: StringService, public errorService: ErrorService, private sanitizer: DomSanitizer,
     scriptService:ScriptService) {
    scriptService.load('https://miro.com/app/static/boardsPicker.1.0.js')
  }


  open() {
    return new Promise<any>((resolve, reject) => {
      const windowRef = window.open(
        '',
        '_blank',
        `width=800,height=800,left=200,top=200,menubar=0,toolbar=0,location=0,personalbar=0,status=0`
      )

      this.jwtService.setOptionsForMiro(environment.miroConfig.issuer, '24h');
      
      //let token = await this.jwtService.getTokenAsynchron(environment.miroConfig.secret)

      miroBoardsPicker.open({
        clientId: environment.miroConfig.issuer, // 1) Put your 'clientId' here.
        action: 'access-link',
        allowCreateAnonymousBoards: true, //2) Enable this option 
        getToken: () => this.jwtService.getTokenForPromise(environment.miroConfig.secret), // Provide token in async way this.jwtService.getTokenAsynchron(environment.miroConfig.secret)
        success: data => {
          console.log("Data", data)
          let html = this.stringService.replace(data.embedHtml, '"></iframe>', ' height: 800px; width: 100%;" allowFullScreen></iframe>');
          data.html = html;
          //ocument.getElementById("container").innerHTML = html;
          //document.getElementById("container").style.height = "400";
          //document.querySelector('#container').innerHTML = data.embedHtml
          resolve(data)
        },
        error: e => {
          console.log('on error', e)
          reject(e)
        },
        cancel: () => {
          console.log('on cancel')
          reject(this.errorService.c_canceled)
        },

        windowRef: windowRef, // Optional. Link to an already opened popup window. See example below in case you want lazy loading for boardsPicker.js. 
      })
    })
  }
}
