import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { Board } from './board.model';
import { StorageService } from 'app/shared/services/storage.service';
import { GeneralDataService } from 'app/shared/firebase-services/generalData.service'; 3
import * as alertFunctions from '../../../shared/data/sweet-alerts';

import { PromiseService } from 'app/shared/services/promise.service';
import { User } from 'firebase';
import { MetatagHttpService } from 'app/shared/http-services/metatag-http.service';
import { UploadFile } from 'app/shared/models/uploadfile.model';
import { ImageService } from 'app/shared/services/image.service';
import { UploadService } from 'app/shared/services/upload.service';
import { VideoPortalService } from 'app/shared/services/videoportal.service';
import { ArrayService } from 'app/shared/services/array.service';
import { UserData } from 'app/shared/models/user.model';
import { UserProfile } from 'app/shared/models/userProfile.model';



@Injectable()
export class MyTaskBoardService {

  user: any;
  board: Board;
  public boards: Board[] = [];


  constructor(public generalDataService: GeneralDataService, public storageService: StorageService) {
    this.user = storageService.getUser();
  }

  getMyBoards() {
    return new Promise<any>((resolve, reject) => {
      let boards = [];
      this.generalDataService.getDatasForValueChange('/boards', 'createdBy', '==', this.user.id, false).then(res => {
        for (let index = 0; index < res.length; index++) {
          const element = res[index];
          boards.push(element)
        }
        resolve(boards)
      })
    })
  }


}


