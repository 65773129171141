import { Injectable } from '@angular/core';
import { JitsiService } from 'app/shared/services/jitsi.service';
import { StringService } from 'app/shared/services/string.service';
import { VideoPortalService } from 'app/shared/services/videoportal.service';

@Injectable({
  providedIn: 'root'
})
export class TaskboardCardfunctionService {

  constructor(public jitsiService:JitsiService, public stringService: StringService, public videoPortalService:VideoPortalService) { }

  openVideoCall(task) {
    this.jitsiService.startVideoWindow(task.id)
  }

  openLink(url) {
    console.log("Open ")
    window.open(url, "_blank")
  }

  writeMail(task, list, board) {
    var email = task.url;
    var subject = board.boardTitle;
    var emailBody = 'Hi '
    window.open("mailto:" + email + "?subject=" + subject + "&body=" + emailBody, "_blank")
    //window.location = "mailto:"+email+"?subject="+subject+"&body="+emailBody;
  }

  getVideoUrl(task) {
    if (this.stringService.substringExists(task.url, "iframe") == true) {
      return task.url;
    } else {
      return this.videoPortalService.getEmbadedVideoHtml(task.url)
    }
  }

  
  downloadFile(url) {
    const link = document.createElement('a');
    link.href = url;
    link.download = url;
    link.target = '_blank';
    console.log(link)
    link.click();
  }
}
