import { Router, ActivatedRoute } from '@angular/router';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as alertFunctions from '../../shared/data/sweet-alerts';
import { UserData } from '../models/user.model';
import { StorageService } from './storage.service';
import { GeneralData } from '../models/generalData.model';
import { GeneralDataService } from '../firebase-services/generalData.service';
import { I18n } from '../models/i18n.model';

@Injectable()
export class GlobalDataService {

  private user:UserData;
  private categories = [];

  constructor(private translate: TranslateService, public generalDataService: GeneralDataService) {
    
  }

  setUser(user){
    this.user = user;
  }

  getUser(){
    return this.user;
  }

  initCategories(){
    this.generalDataService.getDatasFor('i18n', "kind", "==", "category", true).then(res =>
      res.map(a => {
        let data = a.payload.doc.data() as I18n;
        let category = {"display": data.identifier,
                        "value": data.value}
        this.categories.push(category)
      })
    )
  }

  getCategories(){
    return this.categories;
  }


}
