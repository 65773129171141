import { ChangeDetectorRef, ComponentFactoryResolver, Injectable, NgZone } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { BoardSettingsModalComponent } from './board-settings-modal/board-settings-modal.component';
import { TaskboardViewerModalComponent } from './taskboard-viewer-modal/taskboard-viewer-modal.component';

@Injectable({
  providedIn: 'root'
})
export class TaskboardGuiService {

  public showNewCard = [[]];
  currentElem = null;

  constructor(public modalService: NgbModal, private spinner: NgxSpinnerService) { }

  showSpinner() {
    this.spinner.show(undefined,
      {
        type: 'square-jelly-box',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true
      });
  }

  hideSpinner() {
    this.spinner.hide();
  }

  getCardBg(task, list, board) {
    if (task.settings != null && task.settings.bgImage != null && task.settings.bgImage != "") {
      return task.settings.bgColor + " url('" + task.settings.bgImage + "')  no-repeat 0 0 / cover"
    }

    if (task.settings != null && task.settings.bgColor != null) {
      return task.settings.bgColor;
    }

    if(list.settings != null && list.settings.bgColorListCards != null){
      return list.settings.bgColorListCards;
    }

    return board.settings.cardColor;
  }

  getListBg(list, board) {
    if (list.settings != null && list.settings.bgImage != null && list.settings.bgImage != "") {
      return list.settings.bgColor + " url('" + list.settings.bgImage + "')  no-repeat 0 0 / cover"
    }

    if (list.settings != null && list.settings.bgColor != null) {
      return list.settings.bgColor;
    }

    return board.settings.listHeaderColor;
  }

  getHeaderBg(board) {
    if (board.settings != null && board.settings.headerBGImage != null && board.settings.headerBGImage != "") {
      return board.settings.headerColor + " url('" + board.settings.headerBGImage + "')  no-repeat 0 0 / cover"
    }

    if (board.settings != null && board.settings.headerColor != null) {
      return board.settings.headerColor;
    }



  }

  isCollapsed(task) {
    if (task.isCollapsed == null || task.isCollapsed == "") {
      return false;
    } else {
      return task.isCollapsed;
    }
  }

  showAddCard(event, i, j) {


  }

  hideAddCard(event, i, j) {

  }

  openView(event, task, board, ah){
    if (event) {
      event.stopPropagation();
    }

    const modalRef = this.modalService.open(TaskboardViewerModalComponent, { size: 'lg', keyboard: false });
    modalRef.componentInstance.id = 0; // should be the id
    modalRef.componentInstance.task = task; 
    modalRef.componentInstance.board = board;
    modalRef.componentInstance.ah = ah;// should be the data

    modalRef.result.then((result) => {
    
    }).catch((error) => {
      console.log(error);
    });
  }

  


}
