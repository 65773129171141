export class Task {
    public orderNo:number;
    public taskId: number;
    public taskTitle: string;
    public badgeClass: string
    public taskMessage: string;
    public messageType: string; //text, image
    public messageCount: string;
    public linkCount: string;
    public createdOn: Date;
    public createdBy: string;
    public assignedTo: any;
    public assignedToImage:any;
    public isUserImg: boolean;
    public status: string;
    public url:string;
    public imageUrl: string;
    public isVisible:boolean;
    public additionalField1:string
    public events: any;
    public eventStartingTime:any;
    public eventEndingTime:any;
    public settings:any;
    public isCollapsed:boolean;
    public isCollapsedForView:boolean;

    constructor(orderNo:number,taskId: number, taskTitle: string, badgeClass: string, taskMessage: string, messageType: string, messageCount: string,linkCount: string, createdOn: Date, createdBy: string, assignedTo: any, 
      assignedToImage: any,isUserImg: boolean, status: string, url:string, imageUrl:string, isVisible:boolean, additionalField1:string) {
      this.orderNo = orderNo;  
      this.taskId = taskId;
      this.taskTitle = taskTitle;
      this.badgeClass = badgeClass;
      this.taskMessage = taskMessage;
      this.messageType = messageType;
      this.messageCount = messageCount;
      this.linkCount =linkCount;
      this.createdOn = createdOn;
      this.createdBy = createdBy;
      this.assignedTo = assignedTo;
      this.assignedToImage = assignedToImage;
      this.isUserImg = isUserImg;
      this.status = status;
      this.url = url;
      this.imageUrl = imageUrl;
      this.isVisible = isVisible;
      this.additionalField1 = additionalField1;
      this.settings=null;
      this.isCollapsed=false;
    }
  }
