
import { ChangeDetectorRef, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { StorageService } from './storage.service';
import { UserService } from './user.service';

@Injectable()
export class LoginService {


  constructor(private translate: TranslateService, public userService: UserService, public storageService: StorageService,
    public spinner: NgxSpinnerService, private router: Router, private route: ActivatedRoute) {

  }


  handleProfile(firebaseUser, providerId, isAnonymous, handleNextScreen, isToUpdate, redirectUrl) {
    let user: any;
    this.userService.getUser(firebaseUser.uid).then(res => {
      if (res.payload.data() && isToUpdate != true) {
        user = res.payload.data();
        this.storageService.setUser(user)
        if (handleNextScreen) {
          this.handleNextScreen(user, redirectUrl);
        }
      } else {
        user = this.userService.buildUserData(firebaseUser, providerId, isAnonymous, null)
        this.userService.setUser(user.id, user).then(res => {
          this.storageService.setUser(user)
          if (handleNextScreen) {
            this.handleNextScreen(user, redirectUrl);
          }
        }).catch(error => {
          console.log(error);
        });
      }
    }).catch(error => {
      console.log(error);
    });
  }

  handleProfileForPromise(firebaseUser, providerId, isAnonymous, userName) {
    return new Promise<any>(async (resolve, reject) => {
      let user: any;
      this.userService.getUser(firebaseUser.uid).then(res => {
        if (res.payload.data()) {
          user = res.payload.data();
          this.storageService.setUser(user)
          resolve(true)
        } else {
          user = this.userService.buildUserData(firebaseUser, providerId, isAnonymous, userName)
          this.userService.setUser(user.id, user).then(res => {
            this.storageService.setUser(user)
            resolve(true)
          }).catch(error => {
            console.log(error);
          });
        }
      }).catch(error => {
        console.log(error);
      });
    })
  }

  handleNextScreen(user, redirectUrl) {
    this.spinner.hide()
    console.log("Redirect", redirectUrl)
    //this.router.navigate(['/dashboard'], { relativeTo: this.route.parent });
    if(redirectUrl == null || redirectUrl == ""){
      this.router.navigate(['/dashboard'], { relativeTo: this.route.parent });
    } else {
      this.router.ngOnDestroy();
      window.location.href = redirectUrl;
    }
    
  }





}
