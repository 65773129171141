import { Router, ActivatedRoute } from '@angular/router';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class DBService {

  c_model_client = "clients";
  c_model_searchlist = "searchlist";
  c_model_news = "news";
  c_model_events = "events";
  c_model_i18n = "i18n";

  constructor(private translate: TranslateService) {
  
  }

  getModelObjects(){

      let models = [
        { value: this.c_model_client, name: this.c_model_client },
        { value: this.c_model_i18n, name: this.c_model_i18n },
        { value: this.c_model_searchlist, name: this.c_model_searchlist, },
        { value: this.c_model_news, name: this.c_model_news },
        { value: this.c_model_events, name: this.c_model_events },
      ];
  
      return models;
  }



}
