import { Router, ActivatedRoute } from '@angular/router';
import { Injectable } from '@angular/core';
import * as firebase from 'firebase/app';
import { AuthService } from '../auth/auth.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { DeviceDetectorService } from 'ngx-device-detector';


//https://firebase.google.com/docs/auth/web/manage-users

@Injectable()
export class DeviceService {
  token: string;
  public loggedInState: firebase.User;
  public loggedInUid: string;
  deviceInfo: any = null;

  constructor(private router: Router, private route: ActivatedRoute,
              private authService: AuthService, public afs: AngularFirestore, private deviceService: DeviceDetectorService) {
                this.deviceInfo = this.deviceService.getDeviceInfo();
                console.log("Device:", this.deviceInfo)
  }

  getDevice() {
    return this.deviceInfo;

    //const isMobile = this.deviceService.isMobile();
    //const isTablet = this.deviceService.isTablet();
    //const isDesktopDevice = this.deviceService.isDesktop();
    //console.log(this.deviceInfo.browser);
    //console.log(isMobile);  // returns if the device is a mobile device (android / iPhone / windows-phone etc)
    //console.log(isTablet);  // returns if the device us a tablet (iPad etc)
    //console.log(isDesktopDevice); // returns if the app is running on a Desktop browser.
    
  }

  isChrome() {
    if(this.deviceInfo.browser != "Chrome"){
      return true;
    } else {
      return false
    };
  }

  isMobile(){
    return this.deviceService.isMobile();
  }

  isDesktopDevice(){
    return this.deviceService.isDesktop();
  }

  isTablet(){
    return this.deviceService.isTablet();
  }

}
