import { Router, ActivatedRoute, } from '@angular/router';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from "@angular/fire/auth";

//import { AngularFireAuth } from "angularfire2/auth";
import { User } from 'firebase';
import { Observable } from 'rxjs';
import * as firebase from 'firebase';
import { StorageService } from '../services/storage.service';
import { UserData } from '../models/user.model';
import { UserProfile } from '../models/userProfile.model';
import { TranslateService } from '@ngx-translate/core';
import { ConstantsService } from '../services/constants.service';
import { LoginService } from '../services/login.service';
import { UserService } from '../services/user.service';
import { StringService } from '../services/string.service';

@Injectable()
export class AuthService {
  private user: Observable<firebase.User>;
  private userDetails: firebase.User = null;


  private userProfile: UserProfile = new UserProfile();
  private userData: UserData = new UserData();
  errorObject = {
    isError: false,
    message: ""
  };

  constructor(public _firebaseAuth: AngularFireAuth, public router: Router, public storageService: StorageService, private route: ActivatedRoute,
    public afAuth: AngularFireAuth, public translate: TranslateService, public constants: ConstantsService, public stringService: StringService) {
    this.user = _firebaseAuth.authState;
    this.user.subscribe(
      (user) => {
        if (user) {
          this.userDetails = user;
        }
        else {
          this.userDetails = null;
        }
      }
    );
  }

  signupUser(email: string, password: string) {
    return new Promise<any>((resolve, reject) => {
      this._firebaseAuth
        .createUserWithEmailAndPassword(email, password)
        .then(function () {
          var user = firebase.auth().currentUser;
          user
            .sendEmailVerification()
            .then(function () {
              resolve(user);
            })
            .catch(function (error) {
              reject(error);
            });
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  signinUser(email: string, password: string) {
    //your code for checking credentials and getting tokens for for signing in user
    // return this._firebaseAuth.signInWithEmailAndPassword(email, password)

    //uncomment above firebase auth code and remove this temp code
    return new Promise(function (resolve, reject) {
      setTimeout(function () {
        resolve(true);
      }, 1000);
    });

  }

  signinUserWithMailAndPassword(email: string, password: string) {
    return this._firebaseAuth.signInWithEmailAndPassword(email, password)
  }

  logout() {
    this._firebaseAuth.signOut();
    this.storageService.setUser(null);
    this.storageService.setRememberMe(false);
    console.log(window.location.host, window.location.href, window.location.protocol + '//' + window.location.host + '/path/to')
    //this.router.navigate(['pages/searchstart'])
    //.then(() => {
    //  window.location.reload();
    //});
    window.open(window.location.protocol + '//' + window.location.host, "_self")
    //this.router.navigate(['pages/login']);
  }

  isAuthenticated(url) {
    if (this.storageService.getUser()) {
      if (this.urlIsAuth(this.storageService.getUser(), url) == true) {
        return true;
      } else {
        return false
      }
    } else {
      console.log(url, this.checkIsUrlAllowed(url))
      if(this.checkIsUrlAllowed(url)){
        return true;
      } else {
        this.router.navigate(["/pages/login"], { relativeTo: this.route.parent });
      }
    }
  }

  checkIsUrlAllowed(url){
    if(this.stringService.substringExists(url, '/pages/taskboard/')){
      return true;
    } else {
      return false;
    }
  }

  urlIsAuth(user, url) {
    if (url == "/pages/create-event") {
      if(this.isRoleAuth(user, this.constants.c_userrole_creator) == true){return true} else {return false}
    } else {
      return true;
    }
  }

  isRoleAuth(user, roleToNeed) {
    if (user.roles.includes(roleToNeed) || user.roles.includes(this.constants.c_userrole_admin) || this.rollIncludesRoles(user.roles, roleToNeed) == true) {
      return (true);
    } else {
      return (false);
    }
  }

  rollIncludesRoles(roles, roleToNeed) {
    if (roleToNeed == this.constants.c_userrole_admin) {
      if (roles.includes(this.constants.c_userrole_admin)) {
        return true;
      } else {
        return false;
      }
    } else if (roleToNeed == this.constants.c_userrole_clientadmin) {
      if (roles.includes(this.constants.c_userrole_admin)) {
        return true;
      } else {
        return false;
      }
    } else if (roleToNeed == this.constants.c_userrole_creator) {
      if (roles.includes(this.constants.c_userrole_admin) == true || roles.includes(this.constants.c_userrole_clientadmin) == true) {
        return true;
      } else {
        return false;
      }
    }
  }

  forgotPassword(email) {
    return new Promise<any>((resolve, reject) => {
      this._firebaseAuth
        .sendPasswordResetEmail(email)
        .then(res => {
          resolve(res);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  sendVerificationMail() {
    return new Promise<any>((resolve, reject) => {
      var user = firebase.auth().currentUser;
      user.sendEmailVerification()
        .then(function () {
          resolve(user);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  }

  resetPassword(code, newPassword) {
    return new Promise<any>((resolve, reject) => {
      firebase
        .auth()
        .verifyPasswordResetCode(code)
        .then(function (email) {
          var accountEmail = email;
          firebase
            .auth()
            .confirmPasswordReset(code, newPassword)
            .then(function (res) {
              resolve(res);
            })
            .catch(function (error) {
              reject(error);
            });
        })
        .catch(function (error) {
          reject(error);
        });
    });
  }

  recoverEmail(code) {
    return new Promise<any>((resolve, reject) => {
      var restoredEmail = null;
      firebase
        .auth()
        .checkActionCode(code)
        .then(function (info) {
          restoredEmail = info["data"]["email"];
          return firebase.auth().applyActionCode(code);
        })
        .then(function () {
          firebase
            .auth()
            .sendPasswordResetEmail(restoredEmail)
            .then(function () {
              resolve("OK");
            })
            .catch(function (error) {
              reject(error);
            });
        })
        .catch(function (error) {
          reject(error);
        });
    });
  }

  signinGit() {
    let instance = new firebase.auth.GithubAuthProvider();
    //let instance = new firebase.auth.GithubAuthProvider_Instance();
    instance.addScope("user:email");
    instance.addScope("user:name");
    return new Promise<any>((resolve, reject) => {
      this.afAuth
        .signInWithPopup(instance)
        .then(res => {

          resolve(res);

        })
        .catch(function (error) {
          error.message = "Login error git";
          reject(error);
        });
    });
  }

  signinAnonym() {
    let instance = new firebase.auth.GithubAuthProvider();
    //let instance = new firebase.auth.GithubAuthProvider_Instance();
    instance.addScope("user:email");
    instance.addScope("user:name");
    return new Promise<any>((resolve, reject) => {
      this.afAuth
        .signInAnonymously()
        .then(res => {

          resolve(res);

        })
        .catch(function (error) {
          error.message = "Login error Anonym";
          reject(error);
        });
    });
  }

  translateValue(input) {
    let value: any;
    this.translate.get(input).subscribe((res: string) => {
      value = res;
    });
    return value;
  }

  signinGoogle() {
    let instance = new firebase.auth.GoogleAuthProvider();
    //let instance = new firebase.auth.GithubAuthProvider_Instance();
    instance.addScope("profile");
    instance.addScope("email");
    return new Promise<any>((resolve, reject) => {
      this.afAuth
        .signInWithPopup(instance)
        .then(res => {
          resolve(res);
        })
        .catch(function (error) {
          error.message = "Login error google";
          reject(error);
        });
    });
  }

  signinMicrosoft() {
    let instance = new firebase.auth.OAuthProvider('microsoft.com');
    //let instance = new firebase.auth.GithubAuthProvider_Instance();
    instance.setCustomParameters({
      // Force re-consent.
      prompt: 'consent',
      // Target specific email with login hint.
      login_hint: 'user@firstadd.onmicrosoft.com'
    });
    return new Promise<any>((resolve, reject) => {
      this.afAuth
        .signInWithPopup(instance)
        .then(res => {
          resolve(res);
        })
        .catch(function (error) {
          error.message = "Login error Microsoft";
          reject(error);
        });
    });
  }

  signinFB() {
    let instance = new firebase.auth.FacebookAuthProvider();
    //let instance = new firebase.auth.GithubAuthProvider_Instance();
    instance.addScope("email");
    instance.addScope("pages_show_list");
    return new Promise<any>((resolve, reject) => {
      this.afAuth
        .signInWithPopup(instance)
        .then(res => {
          resolve(res);
        })
        .catch(function (error) {
          error.message = "Login error Facebook";
          reject(error);
        });
    });
  }

  async linkAnonymUser(type) {
    var provider;
    if (type == 'github') {
      provider = new firebase.auth.GithubAuthProvider();
    }
    if (type == 'facebook') {
      provider = new firebase.auth.FacebookAuthProvider();
    }
    if (type == 'google') {
      provider = new firebase.auth.GoogleAuthProvider();
    }
    if (type == 'mail') {
      provider = new firebase.auth.EmailAuthProvider;
    }
    if (type == 'microsoft') {
      provider = new firebase.auth.OAuthProvider('microsoft.com');
    }

    //var twitterProvider = new firebase.auth.TwitterAuthProvider();
    //provider = new firebase.auth.EmailAuthProvider;
    return new Promise<any>(async (resolve, reject) => {
      (await this.afAuth.currentUser).linkWithPopup(provider).then(function (result) {
        // Accounts successfully linked.
        var credential = result.credential;
        var user = result.user;
        resolve(result);

      }).catch(function (error) {
        reject(error)
        // Handle Errors here.
        // ...
      });
    })


  }
}
