
import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

import * as jwt from 'jsonwebtoken';

//https://firebase.google.com/docs/auth/web/manage-users

@Injectable()
export class JwtService {

  payload:any;
  options: any;

  constructor() {

  }

  getTokenAsynchron(secret) {
    return new Promise<any>((resolve, reject) => {
      jwt.sign(this.payload, secret, this.options, function (err, token) {
        resolve(token)
      });
    })
  }

  signTest(secret){

  }

  getTokenForPromise(secret) {
    let that = this;
    let token = this.getTokenSynchron(secret)
    //
    var promise = new Promise<string>(function (resolve, reject) {
      resolve(token)
    });
    return promise;
  }

  getTokenSynchron(secret) {
    return jwt.sign(this.payload, secret, this.options);
  }

  setIssuer(value) {
    this.options.issuer = value;
  }

  setExpiresIn(value) {
    this.options.expiresIn = value;
  }

  setUser(value) {
    this.payload.user = value;
  }

  setOptionsForMiro(issuer, expiresIn) {
    this.options = {
      issuer: issuer,
      expiresIn: expiresIn
    }
    this.payload = {
      user: ""
    }
  }

  setPayloadForJitsi(header,algorithm, issuer, expiresIn, audience, notBefore, subject, room, userId, userName, userAvatar, userEmail, isModerator: boolean, isLivestreaming: boolean, isRecording: boolean ) {
    this.payload = {
      iss: issuer,
      exp: expiresIn,
      aud: audience,
      nbf: notBefore,
      sub: subject,
      room: room,
      context: {
          user: {
          id: userId,
          name: userName,
          avatar: userAvatar,
          email: userEmail,
          moderator: isModerator
        },
        features: {
          livestreaming: isLivestreaming,
          recording: isRecording
        },
        
      },
      ignoreNotBefore: true
    };
    this.options = {
      header: header,
      //algorithm: algorithm,
    }
  }

  getJitisToken(uuid) {
    const token = jwt.sign({ // Pass your generated private key
      id: uuid, // You can generate your own id and replace uuid()
      name: "Ruwen", // Set the user name
      email: "ruwen.reinhard@aeb.com!", // Set the user email
      avatar: "", // Set the user avatar
      tenant: "vpaas-magic-cookie-d915f7f3353c4a44a651b72f700929c8", // Set the tenant name / tenant unique idenfier
      kid: "vpaas-magic-cookie-d915f7f3353c4a44a651b72f700929c8/d19743" // Set the api key, see https://jaas.8x8.vc/#/apikeys for more info.
    }, 'MIIEvwIBADANBgkqhkiG9w0BAQEFAASCBKkwggSlAgEAAoIBAQCJzJGflRTdPY27 qVUpot/temtFzExZLENE5XS9qS9JDoP4pdPRZ27KMF5Z2ZslDScLCMXy+LWJvy0t 8QJcucPuYBR2uPgEqZIuhs/SM+3fg59kim3cX/slRSHoN7Aj8rhOK3Glv2k9R+i3 NH4+ckX61s4M9aa9SrJMD5D27DUfZlbpoMbeur4xbd7QxBWhCoTQl0OpyhsObqmG 7BRJGtfWyhx3d2XKk4UlUAeZXaeYvg6lj2/ruHUvUYfDRMxXyar3wgUnOc4auAL6 vgmrURb3ngmTMpr/Wtg93onFYMKgko88FcBMTw2SDFHN1w1NfOInD/QBMVut7UtN S0PAYtPXAgMBAAECggEBAIX1sE/CH1FhCIs598eNRkHYM7bcABDkfdTZeB70/Tn5 eYRcuUmmUscONHsGl9NyMNWCRuWIsPrjNmmmFZTNJtNy5hHwiXQG4UyH1DrWLtaO X4LfLCQDzuQRi//zOzIHQPMFOutV5FolsO+BgYT1lGBhOFjS6rC0kBZvP488W/xU DWsUM1oSF/N0DLajT3BboItjdjGlDZxruw2uBQ0zhROIdsRl6C2xTIRhroy0xmXp iwScjnQ4qvwMz/jYnnh1hBGZpkEw7x/igESAG0uUNYye8JKtkVHku3ZJ3uNEjfLR s1ZtPdqWfWA4Z+2GmLMBQgsdrY/OU85HKq1cZQtR2jkCgYEA5a5Fe6nXU0B4eI9Y 2Ef/3cKY6BdxeqLv74xXGSbgJ1T4NgpQDbyi1tuhJvoebjw8zjXQ/dmIxYTPB0PB PuCSsUSEU6PF1QSmDntEzKuFCwGHjIgwyOxXyGL7Q9dftfEsWVI/lCYOCR+q1awr tSx74oUYO0DNMDgFbC1mpTtLhvUCgYEAmZbugTq9sf/7gJiCEQA6cdu/JVvCOwEJ dHzGCBRLyiJ2kgvAS9xqI/PSlkK+dETDDJHV6EL6ZZCHfg1rmWMfw7QwvlcNQHs4 a49AV5i4uhq1DkKhNVYu3cbdMWiSYYXTLpqZufRW1ga+XiLnoKh0qig/RBtbxwVF 8903NeaOOBsCgYEAgqQOl5lyhcMNAx4eHd9ONTgDuzfibywQp8O+ourO4vBcwng5 MqcSRH3mewIXnwIaX/ySZGGAf0DcdThfycD5D2V2kxB7bX/YaDJLXtNMuIblL9Uj UpXEFJHgc6UkppuIa0HyMfU3VI8zn5fvwHyN9rlXoBRq4btrsi1czi0c96UCgYBZ qheLMjPUWtEE/gPQ0QVNuzAYKz11HOHcM4uY4+BrHTuDf4C/yOneG1Xxiv2M+yez iuAKPC/aY0n2ePc8EPZWVjeupzuBTW5evL6fZPVTEIxqytM6RBoQNyyiz53FeU8U ggUE3nRjdoRkiJQY4r9ZJy3oIOCvi5CPEbJNsU+NyQKBgQDfa4qyMExStKyQTFOA dQX6LyjPcWJpoU8yVk1bF3ZGfr8eGqkYx7celxlc8L7xtNQtypcBEEWrVt3vaD/W 2cRSDi87E4acFFdAEpvDRXbzQeB9FohZtZc9rz+eHinX2PPfEKffFrNBLrONPs1o AOGvRNnNWHOA65a3GmtB7Di7Dg==');
  }

}
