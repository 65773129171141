import { Reward } from './reward.model';
import { UserProfile } from './userProfile.model';

export class UserData{
    clients: any;
    id: string;
    email: string;
    roles: string[];
    profile: UserProfile;
    skills: string[]
    level: number;
    points: number;
    rewards: Reward[]
 }
