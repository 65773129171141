import { Injectable } from '@angular/core';
import { FAQ } from '../models/faq.model';

@Injectable()
export class FaqService {

    constructor() { }

    public faqs: FAQ[] = [
        new FAQ(
            1,
            'Was kostet mich die Nutzung von Humany?',
            `Die Nutzung von Humany kostet dich nichts. Du kannst Dank sammeln oder erwerben. Wenn Du Dank erwirbst, zahlst Du pro Dank 1€. <br>Auch die Transaktionen (Dank weitergeben) kosten dich nichts.<br>
            Die Transaktionsgebühren, die auf einer Blockchain gezahlt werden müssen, werden Durch Humany übernommen<br>`,
        ),
        new FAQ(
            2,
            'Was ist der SCA-Score?',
            `Der SCA-Score ist der "Social Consece Algorythmus". Er definiert, wieviel Stimmrecht Du bei der Abstimmung hast. <br>Jedes Vorhaben muss in einer Abstimmung über 50% der Stimmen erhalten, um in die Spendenphase zu kommen.<br>
            Der SCA-Score definiert dabei, welche Stimmstärke Du hast. Ist dein SCA-Score 1,5, hast Du eine halbe Stimme mehr <br>Stimmrecht als eine Person mit SCA-Score 1.<br>
            Der SCA-Score kann bis auf 2 steigen und auf 1 fallen. Der SCA-Score wird aus deinem gespendeten Dank berechnet. <br>Je mehr Dank Du ohne Gegenleistung zur Verfügung stellst, desto höher steigt dein SCA-Score.<br>
            Wenn Du doppelt so viel Dank ohne Gegenleistung gegeben hast, wie Du mit Gegenleistung (Private Auszahlung, Ware des Einzelhandels) <br>genutzt hast, ist dein SCA-Score 1.1. Vervierfacht sich dieses Verhälntis, <br>so hast Du einen SCA-Score von 1.2.<br>
            Dieser wächst exponentiell:<br>
            8    fach: 1.3<br>
            16   fach: 1.4<br>
            32   fach: 1.5<br>
            64   fach: 1.6<br>
            128  fach: 1.7<br>
            256  fach: 1.8<br>
            512  fach: 1.9<br>
            1024 fach: 2<br>`,
        ),
        new FAQ(
            3,
            'Warum kann ich mein Profilbild nicht ändern?',
            `Dein Profilbild und dein Titelbild (Kombination) werden bei jeder Spende oder bei jedem Voting angezeigt. Damit werden die kryptischen Wallet-Adressen <br>
            in Bildsprache umgewandelt. Du kannst so sehen, ob die selbe Person mehrfach gespendet hat oder in mehreren Vorhaben partizipiert, ohne Rückschlüsse<br>
            auf die Person ziehen zu können.`,
        ),
        new FAQ(
            4,
            'Was ist eine Wallet-Adresse?',
            `Die Wallet-Adresse ist deine persönliche Adresse zu deinem Dank. Jeder kann über diese Adresse Dank an dich senden. Wallet-Adressen sind Teil der Blockchain. <br>
            Nur wer den passenden "Private Key" zu einer Wallet-Adresse hat, kann auf den Dank zugreifen. Wir speichern den "Private Key" nicht. <br>`,
        ),
        new FAQ(
            5,
            'Was ist ein "Private Key"',
            `Der "Private Key" ist dein Schlüssel zu deinem Dank. Nur mit dem "Private Key" hast Du Zuriff auf deinen Dank in deinem Wallet.<br>
            Der "Private Key" wird bei der Erstellung deines Accounts automatisch vergeben und passt ausschließlich zu deiner Wallet-Adresse.<br>
            Er kann nicht zurückgesetzt oder geändert werden.<br>
            Humany kennt den "Private Key" von dir nicht. Wir haben den "Private Key" mit deinem Sicherheitsschlüssel verschlüsselt. <br>
            Mit dem Sichherheitsschlüssel wird dein "Private Key" bei Transaktionen entschlüsselt.`,
        ),
        new FAQ(
            6,
            'Was ist der Sicherheitsschlüssel?',
            `Der Sicherheitsschlüssel ist dein Passwort, um Transaktionen zu tätigen oder deinen "Private Key" anzuzeigen.<br> Wir kennen deinen Sichherheitsschlüssel nicht.<br>
            Wenn Du eine Transaktion ausführst und deinen Sicherheitsschlüssel eingibst, verwenden wir Standard-Kryptographie (Hash-Werte). So wird sichergestellt, <br>
            dass deine Daten sicher und geschützt sind. Passe deshalb auf deinen Sicherheitsschlüssel und deinen "Private Key" auf. `,
        ),
        new FAQ(
            7,
            'Warum kann ich den Sicherheitsschlüssel nicht zurücksetzen?',
            `Um deine Daten zu schützen, speichern wir keine sicherheitsrelevanten Daten ab. So kennst nur Du den Sicherheitsschlüssel und den "Private Key".<br>
            Wenn wir dir den "Private Key" anzeigen, ist das eine mathematische Berechnung, die nur in eine Richtung gelöst werden kann (Hash-Berechnung).<br>
            Dafür brauchen wir den Sicherheitsschlüssel, der ebenfalls ein Hash-Wert ist. So stellen wir sicher, dass deine Daten bei dir bleiben.<br>
            Da die Berechnung nur in eine Richtung möglich ist, können wir den "Private Key" nicht mehr herstellen, wenn Du deinen Sichherheitsschlüssel nicht mehr kennst.<br>
            Auch dieser kann aus den genannten gründen nicht geändert werden, sonst würde die Berechnung des "Private Keys" nicht mehr aufgelöst werden können. `,
        ),
        new FAQ(
            8,
            'Warum kann ich einem Vorhaben (noch) nicht spenden?',
            `Einem Vorhaben kann man erst spenden oder damit interagieren, wenn das Vorhaben durch das Voting-Verfahren bestätigt wurde.<br>
             Das Voting ist erst beendet, wenn eine bestimmte Anzahl an Usern abgestimmt hat.<br>
             Diese Anzahl ist abhängig von der Anzahl aller registrierter Nutzer (0.5%). <br>
             Bei 50.000 registrierten Nutzer müssen daher 250 Votes stattfinden. Die Mindestanzahl beträgt 7 Stimmen, die Maximalanzahl beträgt 1000 Stimmen.`,
        ),
        new FAQ(
            9,
            'Wie finanziert sich Humany?',
            `Unternehmen oder Privatpersonen können Dank erwerben. Ein Dank kostet 1€. Dank kann 1:1 weier gegeben werden. Alle sozialen Vorhaben bekommen den Dank 1:1 ausbezahlt.<br>
            Verwendest Du deinen Dank für dich selbst (private Auszahlung), dann bekommst Du 0.50€ ausbezahlt. Die anderen 0,5€ verbleiben bei Humany. <br>
            Daurch wird sichergestellt, dass die Transaktionen kostenfrei bleiben und wir Humany weiterentwickeln können.<br>
            Zusätzlich zahlen alle Vorhaben bei Auszahlung 5% Plattformgebühr. Dadurch werden unsere Fixkosten beglichen.`,
        ),
         new FAQ(
            10,
            'Können Vorhaben, die für Spenden freigegeben werden, auch wieder entfernt werden?',
            `Als Administrator eines Vorhabens kannst du dein Vorhaben jederzeit in den Status gelöscht bringen. Damit ist es für andere Nutzer nicht mehr sichtbar.<br>
            Gehe dazu einfach in das entsprechende Vorhaben und führe die Aktion "Löschen" aus. Derzeit ist nicht vorgesehen, dass ein gelöschtes Vorhaben erneut aktiviert werden kann. `,
        ),
        new FAQ(
            11,
            'Ich habe mein Dank an den falschen Empfänger übergeben. Was kann ich tun?',
            `Leider ist ein einmal vergebener Dank nicht mehr rücknehmbar.<br> Wenn Du die E-Mail-Adresse kennst (Vorhaben, Privatperson), kannst du den Empfänger anschreiben und um Rückgabe bitten.<br>
            Wenn du an eine Walletadresse versendet hast, dann schreibe an support@humany.org. Wir versuchen uns darum zu kümmern.
             `,
        ),
        new FAQ(
            12,
            'Ist es möglich, eine Spendenquittung zu erhalten, wenn ich Dank an ein Vorhaben ohne Gegenleistung gespendet habe?',
            `Aktuell ist dies leider nicht möglich. Nach aktuellem Stand wird aber geplant, eine solche Funktion hinzuzufügen.<br>
             Du bekommst zu jeder Spende eine E-Mail mit dem Spendenbetrag. Diese kannst Du versuchen, beim Finanzamt geltend zu machen.`,
        ),
        new FAQ(
            13,
            '„Mir gefällt Humany sehr gut. Gerne möchte ich Freunde, Bekannte und meinen Arbeitgeber dazu einladen. Wie geht das und werde ich dafür belohnt?',
            `Wir arbeiten bereits an einem Sharing-Programm. Sobald wir genauere Informationen haben, werden wir dir Bescheid geben.
            `,
        ),
        new FAQ(
            14,
            'Wie kann ich Dank erwerben?',
            `1. Registriere Dich bei Humany bzw. logge Dich ein.<br>
             2. Öffne die Registerkarte "Dank erwerben" und gib den gewünschten Betrag ein.<br>
             3. Wenn du deine Rechnungsadresse gepflegt hast und die Datenschutz- und Nutzerbedingungen akzeptiert hast, kannst Du mit Paypal bezahlen.<br>  
             4. Klicke auf „Jetzt Kaufen“. <br>
             5. Du kaufst im Wert von 1:1. `,
        ),
        new FAQ(
            15,
            'Wie kann ich Dank weitergeben?',
            `1. Registriere Dich bei Humany bzw. logge Dich ein.  <br>
             2. Finde die Person, der Du danken willst.   <br>
             3. Falls die Person noch nicht bei Humany registriert ist, tippe die E-Mail-Adresse des Beschenkten ein.<br>
             4. Wenn Du eine pwesönliche Nachricht hinzufügen möchtest, kannst Du das im Feld "persönliche Nachricht" machen. 
             <br>Die Nachricht ist Teil der Empfangs-Mail.  <br>
             5. Der Beschenkte erhält eine Benachrichtigung.  <br> 
             6. Bis zur Registrierung wird der Token sicher verwahrt und verfällt nicht. <br>
             Kennst Du nur eine allgemeine E-Mail (z.B. info@firma.de), füge deiner persönlichen Nachricht eine Anmerkung dazu, an wen der Dank weitergegeben werden soll.`,
        ),
        new FAQ(
            16,
            'Ich habe Dank erhalten. Was nun?',
            `1. Registriere Dich bei Humany bzw. logge Dich ein.<br>
             2. Dein Dank liegt für dich bereit. <br>
             3. Danke jemand anderem oder nutze den Dank privat.<br>`,
        ),
        new FAQ(
            17,
            'Was ist ein Vorhaben?',
            `Ein Vorhaben ist der Oberbegriff für die verschiedenen Möglichkeiten, mit denen du deinen Dank für einen guten Zweck spenden oder als Zahlungsmittel verwenden kannst. <br>
            Insbesondere zählen dazu der Dank an eine Person, die Spende an eine Organisation oder an ein Projekt sowie die Zahlungsmöglichkeit im (sozialen, ökologischen) Einzelhandel.`,
        ),
        new FAQ(
            18,
            'Warum kann ich nur als Mitarbeiter einer Organisation oder eines Projektes einen Vorschlag unterbreiten, dieses als Vorhaben aufzunehmen?',
            `Humany als Plattform, bei der zu einem guten Zweck gespendet werden kann, möchte sichergehen, dass das Geld auch wirklich da ankommt, wo ihr euren Dank hingebt. <br>
             Um dies zu ermöglichen muss sichergestellt werden, dass die Entnahme von der Organisation beziehungsweise dem Projekt erfolgt. <br>
             Aus diesem Grund werden die Kontaktdaten bei Anlage eines Vorhabens abgefragt.<br>
             Wir arbeiten bereits an einem Konzept, wie ihr uns trotzdem Projekte oder NGOs vorschlagen könnt.
             `,
        ),
        new FAQ(
            19,
            'Welcher Browser wird für die Nutzung der Plattform empfohlen?',
            `Es wird empfohlen, Chrome zu verwenden. Bei anderen Browsern kann es zu eingeschränkter Funktionalität oder schlechter Performance kommen.`,
        ),
        new FAQ(
            20,
            'Welche Technologie steckt hinter dem Dank?',
            `Technologisch wird der Dank und damit die zentrale Funktionalität dieser Plattform mit der Blockchain-Technologie, genauer Ethereum ERC20-Token realisiert.`,
        ),
        new FAQ(
            21,
            'Warum dauert die Transaktion beim Senden bzw. der Kauf von Dank so lange?',
            `Jegliche Transaktionen reihen sich in eine Warteschlange aller ERC20-Transaktionen ein. Je nach Tageszeit und aktuellem Transaktionsaufkommen kann die Ausführungszeit daher in der Regel zwischen wenigen Minuten und ca. 30-40 Minuten variieren. Bitte lasse in dieser Zeit den Browser und das Transaktionsfenster geöffnet und aktualisiere nicht das Browserfenster, damit wir deine Transaktion nach Durchführung aktualisieren können und dich über den Erfolg auf dem Laufenden halten können. Du kannst die erwartete Dauer einsehen, indem du im Transaktionsfenster auf den Link-Button klickst.
            Zukünftig wirst du die Möglichkeit haben, einen Boost für eine Transaktion zu kaufen und auf diese Weise einen höheren Rang auf der Warteliste der offenen Transaktionen erhalten.`,
        ),        

    ]
}
