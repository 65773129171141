// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

const  prefix = '';

export const environment = {
  production: false,
  api_endpoint: prefix + '/api/',
  endpoint: prefix,

  paypal_enviroment:'production',

  firebaseConfig: {
    apiKey: "AIzaSyDITIbK6-eJVRbg_cpEmDMoVQhOFXB0mio",
  authDomain: "edugle-2b14f.firebaseapp.com",
  projectId: "edugle-2b14f",
  storageBucket: "edugle-2b14f.appspot.com",
  messagingSenderId: "194436585486",
  appId: "1:194436585486:web:94845f7404a8d0af59445f",
  measurementId: "G-FKKM687FWP"
  },

  miroConfig: {
    issuer: "3074457353454165631",
    secret: "7mgN6ZMetyMCjbo3qphw956JmGbvbPD0",
  },

  jitsiConfig: {
    apiKey: "vpaas-magic-cookie-d915f7f3353c4a44a651b72f700929c8/634486",
    tenant: "vpaas-magic-cookie-d915f7f3353c4a44a651b72f700929c8",
    secret: "-----BEGIN PRIVATE KEY-----MIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQDCCC5lWqkpXqSZZWwcDPXT9ZdEgSECGnIWPgFiTx22KuT96/OqnEnYyn6j6P1oIyYIH6xOT7laD4HGS9UovhMlQYnqNuV93azo1HYjYJ32hziOAWOB0tzZpsXwEoOx1Nn7aVt0FE3e1TQ1UAiBlYxoNtqtHgZI3Z3y5+n99d8iNHbTFysxh3d2+9vMF9rafCCAUIS/4MBpCa2ATe/RFQ1aQxVT82F0cJ84ORkFVHvqBZhp90HYjCGkCGI+RS5yh05evbL6KIxvKNE1MIWq+nzIr63n7K+9b6wtV7MuCfwa4vs1LTielt+X65LFt8Wx+WDZx1ysvz1wuENy3/J6YrwhAgMBAAECggEAdOOq16JDUEnUzRhdhZSFL7i+9ST5dqPp4N5fxRK2anzy0MmpVDms/y4WFraQmoC6M6CpE9BRcCDxZD1g35eKPyJ5bCDAJhA6T1VXCj71SoPxnRLXGGmoZHJkwhjVP3dkDmJNFuP4eWpldkyNo0std8a7/BifXpwrwB7YRwcs8hQtWL2QCHCV1VTOz6prvWySVS7VqZMwPOp9RY4aEvBmtYkXML0gVgeRDruoREuCvxzds4V/ykg7AxfanqwdCaz8PqXurg/D1fj13GUc/okrsgsYtecMyYRAYEluQyuLC6oGizKAM8m7X88APakYT2polhDTn0NRStF80EdT1fvJ9QKBgQD1AwwnzKZ9vkDQTiWlxTf+yhwhg0VmjH/R7AKIqSbN7wKEi2ux1avT91QJyTOGmRqpjdBkvHubrbwIwwoBnur3bb7orHJ5IRtPcsQifm9+aKYQTM0TBDCxWEJYPrT/lpJqgU3fWo/hrA9MfCVymwq1VoHsfIKl5u4BxsxGLnup2wKBgQDKu9bMSrlrpflw1fx0xW52MEfBujLuiROXqgiN0GlHqSNX5i4OLWGgBZe+I14GilZTDoaWQKDgqqEZV+nv8s5C7wkx9Xw5gtdl2x8iB7tA6E2NLoC7yREp5ASDKj+AyQogdFI5t/N3lF/Fvd5o2NXp3hrR76YNgM+bu1buo95oswKBgEPvwGMYv+GF63rW8GFyugAQEpTITWoN8zNF+b3jCTt41n3iGs6I rMhtgAPmoj7kHRx+khYcnTmhhs33JsMkSYqqB5GEn4kuwN/atr/JxyiJ4+IJKaPoOYgzLNJDMPTdz2NwzyLrfZHZn7RZiBirHOEDR6tHKwLNcZlUgjfDk86ZAoGAc/EI3ry9XfsFtKosPvvKOH1UDE7YYeKy+cGcMr5F912bgOF65VC+zC1moq7HTyY+SDG+dp9vYg0zJLNKbpeFrnHV6GRkLt/NH8s4+tVBGdXAxEUcCkjrL/HfQsQenSBcUkMz4x54dqK8QvJbB8s6Sw1ieT180awhDwcMqF2/DFECgYEAg/UoZqAVpPX9Rqt1fwlFHW4eK7nnONGGw2M52b8UMSY7OcqUi0yUX7F/UdiZtgu5AWJpyQhnsCqNNJlqQhdqeUToJIHZDcoX6QUYOVwvjFagP5e2JwSMjHHtoPx3oQ2nQ4AIQNUOmU/351zH6KRJp6s4GZQ/0C1f3zNIRIjXcKg=-----END PRIVATE KEY-----",
    publicKey: "-----BEGIN PUBLIC KEY-----MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAwgguZVqpKV6kmWVsHAz10/WXRIEhAhpyFj4BYk8dtirk/evzqpxJ2Mp+o+j9aCMmCB+sTk+5Wg+BxkvVKL4TJUGJ6jblfd2s6NR2I2Cd9oc4jgFjgdLc2abF8BKDsdTZ+2lbdBRN3tU0NVAIgZWMaDbarR4GSN2d8ufp/fXfIjR20xcrMYd3dvvbzBfa2nwggFCEv+DAaQmtgE3v0RUNWkMVU/NhdHCfODkZBVR76gWYafdB2IwhpAhiPkUucodOXr2y+iiMbyjRNTCFqvp8yK+t5+yvvW+sLVezLgn8GuL7NS04npbfl+uSxbfFsflg2cdcrL89cLhDct/yemK8 IQIDAQAB-----END PUBLIC KEY-----"
  },

  chain: '0x4'

};
