
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';


//https://firebase.google.com/docs/auth/web/manage-users

@Injectable()
export class ToastService {

  constructor( private spinner: NgxSpinnerService,public toastr: ToastrService, public translate: TranslateService) {
    
  }

 show(type, title, message){
  switch (type) {
    case "Info":
      this.toastr.info(this.translateValue(title),this.translateValue(message),{
        positionClass: 'toast-bottom-right'
      });
      break;
    case  "Success":
      this.toastr.success(this.translateValue(title),this.translateValue(message),{
        positionClass: 'toast-bottom-right'
      });
      break;
    case "Error":
      this.toastr.error(this.translateValue(title),this.translateValue(message),{
        positionClass: 'toast-bottom-right'
      });
      break;
    case "Warning":
      this.toastr.warning(this.translateValue(title),this.translateValue(message),{
        positionClass: 'toast-bottom-right'
      });
      break;
  }
 }

 translateValue(input) {
  let value: any;
  this.translate.get(input).subscribe((res: string) => {
    value = res;
  });
  return value;
}

}
