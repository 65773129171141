import { List } from "../taskboard/taskboardlist.model";
import {Md5} from 'ts-md5/dist/md5';

export class Board {
    public boardId: string;
    public boardTitle: string;
    public badgeClass: string
    public boardType: string
    public boardMessage: string;
    public createdOn: Date;
    public createdBy: string;
    public assignedTo: any;
    public status: string;
    public boardLink: string;
    public lists: List[];
    public settings: BoardSettings;
    public boardBgImageUrl:string;
    //public image:string;

    constructor(boardId: string, boardTitle: string, badgeClass: string, boardMessage: string, boardType: string,createdOn: Date, createdBy: string, assignedTo: any,status: string, boardLink: string, boardBgImageUrl:string, settings?: BoardSettings) {
      this.boardId = boardId;
      this.boardTitle = boardTitle;
      this.badgeClass = badgeClass;
      this.boardType = boardType;
      this.boardMessage = boardMessage;
      this.createdOn = createdOn;
      this.createdBy = createdBy;
      this.assignedTo = assignedTo;
      this.status = status;
      this.boardLink = boardLink;
      this.settings = { 
        isPWset: false, 
        cardColor: '#faf0e6', 
        listPadding: 'col-xl-3 col-sm-6 col-12', 
        pwHash: null, 
        userAuthority: 'FORBIDDEN', 
        linkAuthority: 'FORBIDDEN', 
        adminHash: Md5.hashStr(this.createdOn.toString() + this.createdBy + 'Eine kleine Mickey Mouse'),
        listHeaderColor: "#faf0e6",
        headerColor: "#faf0e6",
        headerTextColor: "#000000",
        headerBGImage: null,
        textColor:"#000000",
        listTextColor: "#000000",
        bgColor: "",
        seperateCardTypeColors: true
      }
      this.boardBgImageUrl = null;
    }
  }

  export class BoardSettings {
    isPWset:boolean;
    cardColor: string;
    listPadding: string;
    pwHash:string;
    userAuthority:string;
    linkAuthority:string;
    adminHash:string;
    listHeaderColor: string;
    headerColor:string;
    headerTextColor:string;
    textColor:string;
    listTextColor:string;
    bgColor:string;
    seperateCardTypeColors: boolean;
    headerBGImage:string;
  }

  
