
import { Injectable } from '@angular/core';
import { EduscoreComponent } from 'app/pages/content-pages/eduscore/eduscore.component';
import { GeneralDataService } from '../firebase-services/generalData.service';
import { EduScore } from '../models/eduscore.model';


@Injectable()
export class EduScoreService {

  constructor(public generalDataService: GeneralDataService) {

  }

  updateEdScore(entry){
    let eduScore: EduScore;
    if(entry.eduScore == null || entry.eduScore == {}){
      entry.eduScore = new EduScore();
      entry.eduScore.cumulatedEduScore = 0;
    } else {
      eduScore = entry.eduScore;
    }

    if(entry.eduScore.innovationScore == null){
      entry.eduScore.innovationScore = 0;
    }

    if(entry.eduScore.accessScore == null){
      entry.eduScore.accessScore = 0;
    }

    if(entry.eduScore.relevantScore == null){
      entry.eduScore.relevantScore = 0;
    }

    if(entry.eduScore.sustainabilityScore == null){
      entry.eduScore.sustainabilityScore = 0;
    }

    if(entry.eduScore.benefitScore == null){
      entry.eduScore.benefitScore = 0;
    }


    entry.eduScore.cumulatedEduScore = (entry.currentRating * 10) + entry.eduScore.innovationScore + entry.eduScore.accessScore + entry.eduScore.relevantScore + entry.eduScore.sustainabilityScore + entry.eduScore.benefitScore;

    return entry;
  }


  

}
