import { SocialMedia } from '../services/socialmedia.model';
import { Notification } from './notification.model';

export class UserProfile{
    displayName:string;
    name: string;
    email:string;
    signInMethod: string;
    emailVerified: boolean;
    id: string;
    photoURL: string;
    created: string;
    askForTour: String;
    info: string;
    gender: string;
    company: string
    signInType:string;
    birthDate: string;
    country: string;
    phone: string;
    website: string;
    slogan: string;
    socialMedias: SocialMedia[];
    notifications: Notification[];
    createdDate:number;
 }
    