import { Injectable, ViewChild } from '@angular/core';
import swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { CopyService } from './copy.service';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { i18nService } from './i18n.service';
import { StringService } from './string.service';



//https://firebase.google.com/docs/auth/web/manage-users

@Injectable()
export class PromptService {


  constructor( private translate: TranslateService, public copyService: CopyService, public i18nService: i18nService, public stringService: StringService) {

  }

  promptValue(title, text) {
    return new Promise<any>(async (resolve, reject) => {
      swal.mixin({
        input: 'text',
        confirmButtonText: 'Next &rarr;',
        showCancelButton: true,
        progressSteps: ['1']
      }).queue([
        {
          title: this.translateValue(title),
          text: this.translateValue(text)
        },
      ]).then((result) => {

        if(result.dismiss == "cancel"){
          resolve(null)
        } else {
          resolve(result.value)
        }
      })
    })
  }

  promptOk(title, text) {
    return new Promise<any>(async (resolve, reject) => {
      swal.mixin({
        confirmButtonText: 'OK',
        showCancelButton: true,
      }).queue([
        {
          title: this.translateValue(title),
          text: this.translateValue(text)
        },
      ]).then((result) => {
        if (result.value) {
          resolve(result.value)
        }
      })
    })
  }

 /*  promptHtmlForCopy(title, icon, html, circleId) {
    return new Promise<any>(async (resolve, reject) => {
      swal.fire({
        title: this.translateValue(title),
        //icon: icon,
        html: html,
        showCloseButton: true,
        //showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-danger ml-1'
        },
        onBeforeOpen: () => {
          const content = swal.getContent()
          const $ = content.querySelector.bind(content)
          
          const integrateVideo= $('#integrateVideo')
          const copyUrl = $('#copyUrl')
          const urlInput = $('#urlInput')
          const url2Input = $('#url2Input')
          const copyDeepUrl = $('#copyDeepUrl')

          integrateVideo.addEventListener('click', () => {
            urlInput.value = this.invitationService.addVideoConference(urlInput.value, circleId)
            url2Input.value = this.invitationService.addVideoConference(urlInput.value, circleId)
            //urlInput.value = this.i18nService.translateValue("Link copied")
            //this.copyService.open("Hallo");
          })

          copyUrl.addEventListener('click', () => {
            this.copyService.copyInputMessage(urlInput)

            urlInput.value = this.i18nService.translateValue("Link copied")
            //this.copyService.open("Hallo");
          })
      
          copyDeepUrl.addEventListener('click', () => {
            this.copyService.copyInputMessage(url2Input)
            url2Input.value = this.i18nService.translateValue("Link copied")
            //this.tooltip.open("Hallo");
          })
        },
      }).then(function (result) {
       
      });;
    })
  } */

  promptIFrame(title, html) {
    return new Promise<any>(async (resolve, reject) => {
      swal.fire({
        title: this.translateValue(title),
        //icon: icon,
        html: html,
        showCloseButton: false,
        //showCancelButton: true,
        focusConfirm: true,
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-danger ml-1'
        },
        onBeforeOpen: () => {
                  
        },
      }).then(function (result) {
       
      });;
    })
  }



  translateValue(input) {
    let value: any;
    this.translate.get(input).subscribe((res: string) => {
      value = res;
    });
    return value;
  }

  copyText(){

  }

}
