import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { StorageService } from '../services/storage.service';
import { UserService } from '../services/user.service';
import { ConstantsService } from '../services/constants.service';

@Injectable()
export class AdminAuthGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router, private storageService: StorageService, public userService: UserService, public constants: ConstantsService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.userService.isAuth(this.constants.c_userrole_clientadmin)) {
      return true;
    }
    else {
      this.router.navigate(['/dashboard']);
    }
  }
}
