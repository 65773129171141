
import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';


//https://firebase.google.com/docs/auth/web/manage-users

@Injectable()
export class ScriptService {

  public c_script_cookifirst = "https://consent.cookiefirst.com/banner.js"
  loadAPI: Promise<any>;

  constructor() {

  }


  load(script) {
    this.loadAPI = new Promise((resolve) => {
      this.loadScript(script);
    });
  }

  public loadScript(script) {
    const url = script

    let node = document.createElement('script');
    node.src = url;
    node.type = 'text/javascript';
    node.async = true;
    node.charset = 'utf-8';
    document.getElementsByTagName('head')[0].appendChild(node);
  }


  public loadCookiFirstScript(script, id) {
    this.loadAPI = new Promise((resolve) => {
      const url = script
      let node = document.createElement('script');
      node.src = url;
      var attr = document.createAttribute ("data-cookiefirst-key");
      attr.value = id;
      node.attributes.setNamedItem(attr);
      //b2e3b8cc-5868-45a3-a73c-ae2093d793ae
      node.type = 'text/javascript';
      node.async = true;
      node.charset = 'utf-8';
      document.getElementsByTagName('head')[0].appendChild(node);
    });
  }

}
