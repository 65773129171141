
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { StorageService } from './storage.service';

@Injectable()

export class i18nService {

  c_language_de = "de";
  c_language_en = "en";
  c_language_fr = "fr";

  languageToTranslate:any;

  constructor(public translate: TranslateService, public http: HttpClient, public storageService: StorageService) { }

  
  getLanguage(){
    let languageToUse;
  
  
    if (
      this.storageService.getLanguageToUse() == null ||
      this.storageService.getLanguageToUse() == ""
    ) {
      languageToUse = this.translate.getBrowserLang();
    } else {
      languageToUse = this.storageService.getLanguageToUse();
    }

    if(this.getLanguageToTranslate() != null && this.getLanguageToTranslate() != "" && this.getLanguageToTranslate() != languageToUse){
      console.log("Languge detected for translate", this.getLanguageToTranslate())
      languageToUse = this.getLanguageToTranslate();
    }

    return languageToUse;
  }

  setLanguageToTranslate(language){
    this.languageToTranslate = language;
  }

  getLanguageToTranslate(){
    return this.languageToTranslate;
  }

  getI18NCollection(collection, language){
    return collection + "/locales/" + language;
  }

  getLanguages(){
    let languages = [
      { value: this.c_language_de, name: this.c_language_de },
      { value: this.c_language_en, name: this.c_language_en },
      { value: this.c_language_fr, name: this.c_language_fr },
    ];

    return languages;
  }

  translateValue(input) {
    let value: any;
    this.translate.get(input).subscribe((res: string) => {
      value = res;
    });
    return value;
  }


  
}