
import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { GeneralDataService } from '../firebase-services/generalData.service';
import { Rating } from '../models/rating.model';


//https://firebase.google.com/docs/auth/web/manage-users

@Injectable()
export class RatingService {

  constructor(public generalDataService: GeneralDataService) {

  }


  public determineRatingScore(votes) {
    let vote = 0;
    let i: number;
    for (i = 0; i < votes.length; i++) {
      vote = votes[i].vote + vote;
    }

    let sum: number = 0;
    sum = vote / i;
    return sum.toFixed(1);
  }

  getRatingsForEntries() {
    return new Promise<any>((resolve, reject) => {
      let ratings = [];
      this.generalDataService.getAllDatas("/ratings", false).then(res => {
        res.map(a => {
          let data = a.payload.doc.data() as Rating
          ratings.push(data)
        })
        resolve(ratings)
      });

    })
  }

  addRating(data, newRating){
    if(data.countOfRatings == null){
       data.countOfRatings = '1'
       data.currentRating = newRating
    } else {
      data.countOfRatings = parseInt(data.countOfRatings)  + 1;
      data.currentRating = (data.currentRating + newRating) / data.countOfRatings;
    }
    return data;
  }


 /*  rate(type, id){
    let ratingModel = new Rating()
    ratingModel.userId = this.user.id;
    ratingModel.userMail = this.user.emailToFind;
    ratingModel.vote = this.currentRate;
    ratingModel.votedAt = new Date();

    this.generalDataService.setData("/ratings/" + type, id, ratingModel, false).then(res => {
     
    });
  } */

}
