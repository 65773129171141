import { Injectable } from '@angular/core';
import { GeneralDataService } from 'app/shared/firebase-services/generalData.service';
import { UploadFile } from 'app/shared/models/uploadfile.model';
import { UploadService } from 'app/shared/services/upload.service';
import { TaskboardGuiService } from './taskboard-gui.service';
import { TaskBoardService } from './taskboard.service';
import { ArrayService } from 'app/shared/services/array.service';

@Injectable({
  providedIn: 'root'
})
export class TaskboardCopyService {

  spinnerText: any;

  constructor(private taskBoardGuiService: TaskboardGuiService, private uploadService: UploadService, private taskBoardService: TaskBoardService, 
    private generalDataService: GeneralDataService, public arrayService: ArrayService) { }

  async copyTask(list, task, board) {
    let files = [];

    //let newTask: any = { ...task };
    let newTask = Object.assign({}, task);
    newTask.orderNo = task.orderNo + 1;
    console.log(list.tasks.length, task.orderNo, newTask.orderNo)
    newTask.taskId = null;
    newTask.id = null;
    newTask.createdOn = new Date();
    newTask.taskTitle = task.taskTitle + '(copy)';

    this.spinnerText = "Karte wird kopiert...";
    this.taskBoardGuiService.showSpinner();

    if (task.files != null) {
      for (let index = 0; index < task.files.length; index++) {
        const element = task.files[index];
        let blob = await this.uploadService.getBase64FromUrl(element.url)
        if (task.messageType == 'picture') {
          let item = new UploadFile();
          item.base64 = blob;
          item.name = element.name;
          item.size = element.size;
          item.type = element.type;
          item.url = '';
          files.push(item)
        } else if (task.messageType == 'file') {
          var file = new File([blob.toString()], element.name, { type: element.type, lastModified: new Date().getTime() })
          files.push(file)
        }
      }

      this.taskBoardService.handleTaskData(list.id, newTask, files, board.boardId).then(taskResult => {
        newTask.files = taskResult.files;
        this.taskBoardService.updateTask(task, list, newTask);
        //this.taskBoardGuiService.hideSpinner();
      }).catch(error => {
        this.taskBoardGuiService.hideSpinner();
      })
    }


    this.generalDataService.setData("/boards/" + board.boardId + '/lists/' + list.id + '/tasks', newTask, false).then(res => {
      newTask.taskId = res.id;
      newTask.id = res.id;
      this.generalDataService.updateData("/boards/" + board.boardId + '/lists/' + list.id + '/tasks', newTask.taskId, newTask, false).then(async res => {
        list.tasks.splice(newTask.orderNo, 0, newTask);
        await this.taskBoardService.updateListCardsWithPromise(list, board);
        //this.taskBoardGuiService.hideSpinner();
        list.tasks = await this.generalDataService.getAllDatasValueChange('/boards/' + board.boardId + '/lists/' + list.id + "/tasks", false);
        list.tasks = this.arrayService.sortBy(list.tasks, 'orderNo');
        
        //list.tasks.splice(newTask.orderNo, 0, newTask);
        this.taskBoardGuiService.hideSpinner();
      })
    });

  }

}
