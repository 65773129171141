import { Router, ActivatedRoute } from '@angular/router';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as alertFunctions from '../../shared/data/sweet-alerts';
import { GeneralDataService } from '../firebase-services/generalData.service';

@Injectable()
export class ConstantsService {

  public c_client_main = "main"
  public c_client_all = "all"

  public c_param_invitationcode = "invitationcode";
  public c_param_description = "description";
  public c_param_clearcache = "clearcache";
  public c_param_flowid = "flowid";
  public c_param_taskid = "taskid";
  public c_param_meetid = "meeting";
  public c_param_circleid = "circleid";
  public c_param_notificationid = "nid";
  public c_param_type = "type";

  public c_param_type_invitation = "invitation";
  public c_param_type_creation = "creation";
  public c_param_type_taskinvitation = "taskinvitation";

  public c_userrole_user = "USER";
  public c_userrole_admin = "ADMIN";
  public c_userrole_clientadmin= "CLIENTADMIN";
  public c_userrole_creator = "CREATOR";

  public c_methdodId = "methods";
  public c_toolId = "tools";
  public c_taskId = "tasks";
  public c_flowId = "flows";
  public c_linkId = "links";
  public c_networkId = "networks";
  public c_inputId = "inputs";

  public c_public = "PUBLIC";
  public c_private = "PRIVATE";

  public c_published = "PUBLISHED";
  public c_open = "OPEN";
  public c_teamflow = "TEAMFLOW";

  public c_processingStatus_open = "OPEN";
  public c_processingStatus_processing = "PROCESSING";
  public c_processingStatus_finished = "FINISHED";
  public c_processingStatus_archived = "ARCHIVED"; 

  public c_flowType_singleTask = "Single Task";
  public c_flowType_multiTask = "Multi Task"

  public c_linkType_information = "Info";
  public c_linkType_callToAction = "CallToAction";
  public c_linkType_video = "Video";
  public c_linkType_image = "Image"
  public c_linkType_randomimage = "Random Image";
  public c_linkType_youtube = "Youtube";
  public c_linkType_html = "HTML";

  public c_kindOfWork_remote = "Remote";
  public c_kindOfWork_meet = "Meet";
  public c_kindOfWork_hybrid = "Hybrid"

  public c_period_daily = "Daily"
  public c_period_weekly = "Weekly"
  public c_period_monthly = "Monthly"
  public c_period_once = "Once"

  public c_flowkind_oic = "Open Innovation Circle";
  public c_flowkind_startup = "Startup"
  public c_flowkind_methods = "Methode"
  public c_flowkind_general = "General"
  public c_flowkind_innovation = "Innovation"
  public c_flowkind_subflow = "Subflow"

  public c_notificationtype_innvitation = "Innvitation";
  public c_notificationtype_news = "News"

  public c_notificationstatus_new = "New";
  public c_notificationstatus_readed = "Readed"

  public c_action_created = "Created"
  public c_action_accepted = "Accepted"
  public c_action_declined = "Declined"

  public c_i18n = "i18n";

  public c_false = "false";
  public c_true = "true";

  public c_linkType_image_thumbnail ="pl-1";
  public c_linkType_image_small ="pl-0";
  public c_linkType_image_normal ="normal";
  public c_linkType_image_big ="big";



  constructor(private translate: TranslateService) {

  }

  translateValue(input) {
    let value: any;
    this.translate.get(input).subscribe((res: string) => {
      value = res;
    });
    return value;
  }

  getIdsForCreation(){

    let ids = [
      { value: this.c_methdodId, name: "Methode" },
      { value: this.c_toolId, name: "Tool" },
      { value: this.c_linkId, name: "Link", },
      { value: this.c_networkId, name: "Network" },
      { value: this.c_inputId, name: "Input" },
    ];

    return ids;
  }

  getIdsForClientCopy(){
    let ids = [
      { value: this.c_methdodId, name: "Methode" },
      { value: this.c_toolId, name: "Tool" },
      { value: this.c_taskId, name: "Task" },
      { value: this.c_linkId, name: "Link", },
      { value: this.c_networkId, name: "Network" },
      { value: this.c_inputId, name: "Input" },
      { value: this.c_flowId, name: "Flows" },
      { value: this.c_i18n, name: "I18n" },
    ];

    return ids;
  }

  getBooleans(){
    let booleans = [
      { value: this.c_false, name: this.c_false },
      { value: this.c_true, name: this.c_true },
    ];

    return booleans;
  }

  getTaskInputTypes(){
    let ids = [
      { value: "None", name: "None" },
      { value: "Textarea", name: "Textarea" },
      { value: "Text", name: "Text" },
      { value: "Hashtag", name: "Hashtag" },
      { value: "Checkin", name: "Checkin" },
      { value: "Checkout", name: "Checkout" },
      { value: "YNPoll", name: "YNPoll" },
//      { value: "Image", name: "Image" },
//      { value: "List", name: "List" }
    ];

    return ids;
  }

  getSkills(){
    let skills = [];
    let methods = this.getMethodTypes();
    let tools = this.getToolTypes();
    
    skills = methods.concat(tools);
    return skills;
  }




  getMethodTypes() {

    let types = [
      { value: 'Warm-Up', name: "Warm-Up" },
      { value: 'Online', name: "Online" },
      { value: 'Workshop', name: "Workshop" },
      { value: 'Teambuilding', name: "Teambuilding" },
      { value: 'Ideation', name: "Ideation", },
      { value: 'Idea evaluation', name: "Idea evaluation" },
      { value: 'Solution finding', name: "Solution finding" },
      { value: 'Consultation', name: "Consultation" },
    ];

    return types;
  }

  getLinkTypes() {
    let types = [
      { value: 'Template', name: "Template" },
      { value: 'Knowledge', name: "Knowledge" },
      { value: 'Information', name: "Information", },
      { value: 'Plattform', name: "Plattform" },
      { value: 'Community', name: "Community" },
      { value: 'File', name: "File" },
      { value: 'Tool', name: "Tool" },
    ];

    return types;
  }

  getInputTypes() {
    let types = [
      { value: 'Video', name: "Video" },
      { value: 'Reading', name: "Reading" },
      { value: 'Listening', name: "Listening" },
      { value: 'PowerPoint', name: "PowerPoint" },
      { value: 'File', name: "File" },
    ];

    return types;
  }

  getNetworkTypes() {
    let types = [
      { value: 'Agency', name: "Agency" },
      { value: 'Person', name: "Person" },
      { value: 'Coach', name: "Coach" },
      { value: 'Company', name: "Company" },
      { value: 'Rooms', name: "Rooms" },
    ];

    return types;
  }

  getTaskTypes() {
    let types = [
      { value: 'KB Input', name: "KB Input" },
      { value: 'Input', name: "Input" },
      { value: 'Conversion', name: "Conversion" },
      { value: 'Exchange of results', name: "Exchange of results" },
      { value: 'Storytelling', name: "Storytelling" },
      { value: 'Task', name: "Task" },
      { value: 'Brainstorming', name: "Brainstorming" },
      { value: 'Poll', name: "Poll" },
      { value: 'Answer', name: "Answer" },
      { value: 'Result', name: "Result" },
    ];

    return types;
  }

  getToolTypes() {
    let types = [
      { value: 'Shaping', name: "Shaping" },
      { value: 'Marketing', name: "Marketing" },
      { value: 'Collaboration', name: "Collaboration", },
      { value: 'Video', name: "Video" },
      { value: 'Conference', name: "Conference" },
      { value: 'Cloud', name: "Cloud" },
      { value: 'Design', name: "Design" },
      { value: 'E-Mail', name: "E-Mail" },
      { value: 'CRM', name: "CRM" },
      { value: 'Orders', name: "Orders" },
      { value: 'Learning', name: "Learning" },
      { value: 'CMS', name: "CMS" },
      { value: 'Project management', name: "Project management" },
      { value: 'Password', name: "Password" },
      { value: 'Social media', name: "Social media" },

    ];

    return types;
  }


  getCategorieForId(id) {
    switch (id) {
      case this.c_methdodId:
        return this.c_methdodId;
      case this.c_toolId:
        return this.c_toolId;
      case this.c_taskId:
        return this.c_taskId;
      case this.c_networkId:
        return this.c_networkId;
      case this.c_inputId:
        return this.c_inputId;
    }
  }

  getIconForCategory(id){
    switch (id) {
      case this.c_methdodId:
        return 'ft-briefcase'
      case this.c_toolId:
        return 'ft-umbrella'
      case this.c_networkId:
        return 'ft-users'
      case this.c_inputId:
        return 'ft-book'
      case this.c_linkId:
        return 'fa fa-map-signs'
        case this.c_flowId:
          return 'icon-plane'  
    }
  }

  getCategoriesforId(id) {
    switch (id) {
      case this.c_methdodId:
        return this.getMethodTypes();
      case this.c_toolId:
        return this.getToolTypes();
      case this.c_taskId:
        return this.getTaskTypes();
      case this.c_networkId:
        return this.getNetworkTypes();
      case this.c_inputId:
        return this.getInputTypes();
      case this.c_linkId:
        return this.getLinkTypes();
      case this.c_flowId:
        return this.getFlowKindsForKB();
    }
  }

  getFlowTypes(){
    let types = [
      { value: this.c_flowType_singleTask, name: this.c_flowType_singleTask },
      { value: this.c_flowType_multiTask, name: this.c_flowType_multiTask },
    ];

    return types;
  }

  getSingleLinkTypes(){
    let types = [
      { value: this.c_linkType_callToAction, name: this.c_linkType_callToAction },
      { value: this.c_linkType_information, name: this.c_linkType_information },
      { value: this.c_linkType_image, name: this.c_linkType_image },
      { value: this.c_linkType_randomimage, name: this.c_linkType_randomimage },
      { value: this.c_linkType_video, name: this.c_linkType_video },
      { value: this.c_linkType_youtube, name: this.c_linkType_youtube },
      { value: this.c_linkType_html, name: this.c_linkType_html },
    ];

    return types;
  }

  getSingleLinkImageSizes(){
    let types = [
      { value: this.c_linkType_image_thumbnail, name: this.c_linkType_image_thumbnail },
      { value: this.c_linkType_image_small, name: this.c_linkType_image_small },
      { value: this.c_linkType_image_normal, name: this.c_linkType_image_normal},
      { value: this.c_linkType_image_big, name: this.c_linkType_image_big},
    ];

    return types;
  }

  getFlowKinds(){
    let types = [
      { value: this.c_flowkind_oic, name: this.c_flowkind_oic },
      { value: this.c_flowkind_subflow, name: this.c_flowkind_subflow },
      { value: this.c_flowkind_startup, name: this.c_flowkind_startup },
      { value: this.c_flowkind_innovation, name: this.c_flowkind_innovation },
      { value: this.c_flowkind_general, name: this.c_flowkind_general },
      { value: this.c_flowkind_methods, name: this.c_flowkind_methods },
    ];

    return types;
  }

  getVisibilities(){
    let types = [
      { value: this.c_public, name: this.c_public },
      { value: this.c_private, name: this.c_private },
    ];

    return types;
  }

  getFlowKindsForKB(){
    let types = [
      { value: this.c_flowkind_oic, name: this.c_flowkind_oic },
      { value: this.c_flowkind_startup, name: this.c_flowkind_startup },
      { value: this.c_flowkind_innovation, name: this.c_flowkind_innovation },
      { value: this.c_flowkind_general, name: this.c_flowkind_general },
      { value: this.c_flowkind_methods, name: this.c_flowkind_methods },
    ];

    return types;
  }

  convertStringToBool(str){
    return ((str === "True") || (str === "true")) ? true:false;
  }


}
