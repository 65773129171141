import { Injectable } from '@angular/core';

import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { GeneralDataService } from 'app/shared/firebase-services/generalData.service';
import { StringService } from 'app/shared/services/string.service';
import { ToolService } from 'app/shared/services/tool.service';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class SearchToolService {

  tools:Observable<any>;
  private itemsCollection: AngularFirestoreCollection<any>;

  constructor(public generalDataService: GeneralDataService, private afs: AngularFirestore, public stringService:StringService, public toolService: ToolService) { 
    this.itemsCollection = afs.collection<any>('tools');
    this.tools = this.itemsCollection.valueChanges();
  }

  ngOnInit(){
   
  }

  /* async getTools(){
    if(this.tools.length > 0){
      return this.tools;
    } else {
      return await this.loadTools()
    }
  } */

  getTools(){
    return this.tools;
  }

  loadTools() {
    let tools = [];
    return new Promise<any>((resolve, reject) => {
      this.generalDataService.getAllDatasWithoutClient('tools').then(res => {
        res.map(a => {
          let data = a.payload.doc.data();
          //let data = a;
          tools.push(data)
        })
        resolve(tools)
      });
    })
  }

  async generateNewLink(tool) {
    if (tool.generationType == 'id') {
      return this.stringService.replace(tool.generationUrl, '<id>', this.getUniqueId(3))
    } else if (tool.generationType == 'service') {
      return await this.toolService.getUrl(tool)
    } else if (tool.generationType == 'url') {
      return await tool.generationUrl;
    } else {
      return tool.host[0] + '/' + this.getUniqueId(3);
    }
  }

  getUniqueId(parts) {
    const stringArr = [];
    for (let i = 0; i < parts; i++) {
      // tslint:disable-next-line:no-bitwise
      const S4 = (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
      stringArr.push(S4);
    }
    return stringArr.join('');
  }
}
