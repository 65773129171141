import { Router, ActivatedRoute } from '@angular/router';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';


import * as _ from 'underscore';
import { UserData } from '../models/user.model';

@Injectable()
export class ArrayService {

  private user: UserData;

  constructor(private translate: TranslateService) {

  }

  removeDublicates(array, key) {
    return [
      ...new Map(
        array.map(x => [key(x), x])
      ).values()
    ]
  }

  removeDublicatesWOKey(array){
    let uniqueArray = array.filter(function(item, pos) {
      return array.indexOf(item) == pos;
    })

    return uniqueArray;
  }

  filterValue(array, key, value){
    return array.find(function(v){ return v[key] === value});
  }



  copyWithValue(array, key, value){
    return array.filter(function(v){ return v[key] !== value});
  }

  getOccurrence(array, key, value) {
    return array.filter(function(v){ return v[key] === value}).length
  }

  filterSimpleValue(array, value){
    return array.find(element => element == value);
  }

  sortBy(array, value){
    array = _.sortBy(array, value)
    return array;
  }

  getLastEntry(){

  }

  swapElement(array, indexA, indexB) {
    var tmp = array[indexA];
    array[indexA] = array[indexB];
    array[indexB] = tmp;
    return array;
  }

  // creates a copy, to detect changes
  immutableMove(array, from, to) {
    return array.reduce((prev, current, idx, self) => {
      if (from === to) {
        prev.push(current);
      }
      if (idx === from) {
        return prev;
      }
      if (from < to) {
        prev.push(current);
      }
      if (idx === to) {
        prev.push(self[from]);
      }
      if (from > to) {
        prev.push(current);
      }
      return prev;
    }, []);
  }


  
}
