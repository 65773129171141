import { RouteInfo } from './vertical-menu.metadata';

//Sidebar menu Routes and data
export const ROUTES: RouteInfo[] = [
  {
    path: '/dashboard', title: 'Dashboard', icon: 'ft-home', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  },
  {
    path: '/pages/mytaskboards', title: 'My edupads', icon: 'icon-grid', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  },
  {
    path: '', title: 'Account', icon: 'ft-user', class: 'has-sub', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false,
    submenu: [
        /* { path: '/pages/profile/me', title: 'Profile', icon: 'ft-user-check submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] }, */
        { path: '/pages/accountsettings', title: 'Account Settings', icon: 'ft-settings submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
       /*  {
            path: '', title: 'Second Level Child', icon: 'ft-arrow-right submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
            submenu: [
                { path: '/YOUR-ROUTE-PATH', title: 'Third Level 1.1', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
                { path: '/YOUR-ROUTE-PATH', title: 'Third Level 1.2', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            ]
        }, */
    ]
  },
/*   {
    path: '/pages/knowledgebase', title: 'Knowledge Base', icon: 'ft-book', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  },
  {
    path: '/pages/faq', title: 'FAQ', icon: 'ft-book', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  }, */


];

export const CREATEROUTES: RouteInfo[] = [
  {
    path: '', title: 'Anlegen', icon: 'ft-edit', class: 'has-sub', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false,
    submenu: [
        { path: '/pages/proposal', title: 'Eintrag anlegen', icon: 'fa fa-search-plus submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
       /*  {
            path: '', title: 'Second Level Child', icon: 'ft-arrow-right submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
            submenu: [
                { path: '/YOUR-ROUTE-PATH', title: 'Third Level 1.1', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
                { path: '/YOUR-ROUTE-PATH', title: 'Third Level 1.2', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            ]
        }, */
    ]
  },

];


export const ADMINROUTES: RouteInfo[] = [
  {
    path: '', title: 'Admin', icon: 'ft-server', class: 'has-sub', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false,
    submenu: [
        { path: '/pages/dbadmin', title: 'DB-Admin View', icon: 'ft-database submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        { path: '/pages/admin/import', title: 'Import', icon: 'ft-folder-plus submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        { path: '/pages/admin/adminfunctions', title: 'Funktionen', icon: 'ft-hard-drive submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        { path: '/pages/admin/eduscore', title: 'EduScore', icon: 'ft-hard-drive submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
       /*  {
            path: '', title: 'Second Level Child', icon: 'ft-arrow-right submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
            submenu: [
                { path: '/YOUR-ROUTE-PATH', title: 'Third Level 1.1', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
                { path: '/YOUR-ROUTE-PATH', title: 'Third Level 1.2', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            ]
        }, */
    ]
  },

];
