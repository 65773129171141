import { Router, ActivatedRoute } from '@angular/router';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as alertFunctions from '../../shared/data/sweet-alerts';
import { Reward } from '../models/reward.model';
import { GeneralDataService } from '../firebase-services/generalData.service';

@Injectable()
export class RewardService {


  constructor(private translate: TranslateService, public generalDataService: GeneralDataService) {
  
  }

  translateValue(input){
    let value:any;
    this.translate.get(input).subscribe((res: string) => {
        value = res;
    });
    return value;
}
 

getFirstReward(){
  let firstReward = new Reward;
  firstReward.kind = "Award"
  firstReward.type = "First One"
  firstReward.icon = "ft-award"
  firstReward.title = "First award title";
  firstReward.description = "First award description";
  firstReward.hasButton = true;
  firstReward.btnTitle = "My first trip";
  firstReward.link = "startFirstFlow()";
  firstReward.description = "First award description";
  firstReward.createdAt = Date.now();
  return firstReward;
}


}
