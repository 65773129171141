import { EduScore } from "./eduscore.model";
import { Rating } from "./rating.model";

export class SearchListItem{
    id: string
    title:string;
    searchDescription:string;
    description: string;
    categories:String[]
    url: string;
    name:string;
    icon:string;
    type:string;
    typeDescription:string;
    kind:string;
    kindDescription:string;
    searchString:string;
    eduScore: EduScore;
    status: string;
    imageUrl:string;
    objectID:string;
    createdAt: Date;
    contact: string;
    contactType: string;
    inputSource: string;
    ratings: Rating[];
    currentRating: number;
    currentEduScore: number; 
    countOfRatings: number;
    videoUrl:string;
    videoUrls:string[]
    host:string;
    crawlerBlocked:boolean;
    teacherRecommendation:boolean;

    constructor(){
        this.id = "";
        this.title = "";
        this.searchDescription = "";
        this.description = "";
        this.categories = [];
        this.url = "";
        this.name = "";
        this.icon = "";
        this.type = "Entry";
        this.typeDescription = "";
        this.kind = "";
        this.kindDescription = "";
        this.searchString = "";
        this.eduScore = null;
        this.status = "PUBLISHED"
        this.imageUrl = "";
        this.objectID = "";
        this.createdAt = new Date();
        this.contact = "";
        this.contactType = "";
        this.inputSource = "";
        this.ratings = [];
        this.currentRating = 0; 
        this.countOfRatings = 0;
        this.videoUrls = [];
        this.videoUrl = "";
        this.host = "";
        this.crawlerBlocked = false;
        this.teacherRecommendation = false;
      }


 }







    