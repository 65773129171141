
import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { GeneralDataService } from '../firebase-services/generalData.service';
import { MyPhpHttpService } from '../http-services/myphp-http.service';
import { EduScore } from '../models/eduscore.model';
import { Rating } from '../models/rating.model';
import { SearchListItem } from '../models/searchlistitem.model';
import { ArrayService } from '../services/array.service';
import { StringService } from '../services/string.service';


//https://firebase.google.com/docs/auth/web/manage-users

@Injectable()
export class MetaTagEdukiService {

  constructor(public generalDataService: GeneralDataService, public stringService: StringService, public arrayService: ArrayService, public phpService: MyPhpHttpService) {

  }


  convertItem(metaTags, url, inputSource, additionalSearchString) {
    console.log("Metatags:", metaTags)

    if (metaTags == null) {
      return;
    }

    let searchItem = new SearchListItem();
    searchItem.url = url;

    searchItem.videoUrls = this.getVideoUrls(metaTags.iframe)
    searchItem.title = this.determineTitle(metaTags)
    searchItem.description = this.determineDescription(metaTags);
    searchItem.type = this.determineType(metaTags, url, metaTags.host);
    searchItem.eduScore = new EduScore();
    searchItem.imageUrl = this.determineImagesource(metaTags)
    searchItem.inputSource = inputSource;
    searchItem.name = this.determineTitle(metaTags)
    searchItem.searchDescription = this.determineSearchDescription(metaTags);
    searchItem.searchString = this.determineSearchString(metaTags);
    if (additionalSearchString != null && additionalSearchString != "") {
      searchItem.searchString = searchItem.searchString + " " + additionalSearchString;
    }
    searchItem.status = 'PUBLISHED';
    searchItem.createdAt = new Date();
    searchItem.contact = this.determineContact();
    searchItem.contactType = this.determineContactType();
    searchItem.countOfRatings = 0;
    searchItem.currentRating = 0;
    searchItem.host = metaTags.host;
    searchItem.crawlerBlocked = this.determineIsCralwerBlocked(metaTags);
    searchItem.categories = this.determineCategories(metaTags);

    console.log("Search-Item:", searchItem);
    this.handlePHPSave(metaTags)

    return searchItem;
  }

  handlePHPSave(metaTags) {
    if (metaTags.p[0] != null && metaTags.p[0].text != null)
      this.phpService.storeLink('storelink.php', metaTags.p[0].text, metaTags.host, metaTags.url, "", "").then(res => {
        console.log("PHP Store:", res)
      })
  }


  determineCategories(metatag) {
    let categories = [];

    if (this.stringService.substringExists(metatag.url, "/material") == true) {
      categories = this.addCategory(categories, 'Material', 'Material')
    }

    for (let index = 0; index < metatag.div.length; index++) {
      const element = metatag.div[index];
      if (element.attributes != null && element.attributes.class != null && element.attributes.class == "featured-category") {
        if (this.stringService.substringExists(element.text, "OER") || this.stringService.substringExists(element.text, "Open Educational Resources") || this.stringService.substringExists(element.text, "freie Bildungsressourcen")) {
          categories = this.addCategory(categories, 'OER', 'OER')
        }
      }
      if (element.attributes != null && element.attributes.class != null && element.attributes.class == "price-block-wrapper") {
        if (this.stringService.substringExists(element.text, "kostenlos")) {
          categories = this.addCategory(categories, 'Kostenlos', 'Kostenlos')
        }
      }
    }

    categories = this.arrayService.removeDublicates(categories, it => it.value);

    return categories;
  }

  determineTitle(metaTags) {
    if (metaTags.title != null && metaTags.title != "") {
      return metaTags.title;
    } else if (metaTags.h1 != null && metaTags.h1[0] != null && metaTags.h1[0] != [] && metaTags.h1[0].text != null && metaTags.h1[0].text != "") {
      return metaTags.h1[0].text;
    } else if (metaTags.p != null && metaTags.p[0] != null && metaTags.p[0].text != null && metaTags.p[0].text != "Dein Warenkorb ist leer.") {
      return metaTags.host + ' - ' + metaTags.p[0].text;
    } else if (metaTags.p != null && metaTags.p[0] != null &&  metaTags.p[0].text == "Dein Warenkorb ist leer." && metaTags.p[1] != null && metaTags.p[1].text != "") {
      return metaTags.host + ' - ' + metaTags.p[1].text;
    } else {
      return metaTags.host
    }
  }

  determineDescription(metaTags) {
    if (metaTags.description != null && metaTags.description != '') {
      return metaTags.description;
    } else {
      if (this.determineDescriptionString(metaTags) != null) {
        return this.determineDescriptionString(metaTags);
      } else {
        return "Die Seite hat " + metaTags.url + " hat keine Meta-Tags definiert. Aber trotzdem gute Inhalte. Wir werden versuchen mit den Seitenbetreibern in Kontakt zu treten, um die Beschreibung zu verbessern."
      }
    }
  }

  determineSearchDescription(metaTags) {
    if (metaTags.description != null && metaTags.description != '') {
      return metaTags.description;
    } else {
      if (this.determineDescriptionString(metaTags) != null) {
        return this.determineDescriptionString(metaTags);
      } else {
        return "Die Seite hat " + metaTags.url + " hat keine Meta-Tags definiert. Aber trotzdem gute Inhalte. Wir werden versuchen mit den Seitenbetreibern in Kontakt zu treten, um die Beschreibung zu verbessern."
      }
    }
  }


  getVideoUrls(iframes) {
    let urls = [];
    if (iframes != null) {
      for (let index = 0; index < iframes.length; index++) {
        const element = iframes[index];
        if (element.attributes.class == "video-embed" || element.attributes.allowfullscreen != null) {
          urls.push(element.attributes.src);
        }
      }
      return urls;
    }
  }

  determineType(metaTags, url, host) {
    if (this.isFile(url)) {
      return 'File';
    } else if (this.stringService.substringExists(host, 'twitter') || this.stringService.substringExists(host, 'facebook') || this.stringService.substringExists(host, 'linkedin') || this.stringService.substringExists(host, 'xing')) {
      return 'Account';
    } else if (this.stringService.substringExists(host, 'youtube') && this.stringService.substringExists(host, 'channel')) {
      return 'Account';
    } else if (this.stringService.substringExists(host, 'youtube') || this.stringService.substringExists(host, 'vimeo')) {
      return 'Video';
    } else if (this.stringService.substringExists(host, 'youtube')) {
      return 'Video';
    } else {
      return 'Entry';
    }
  }

  isFile(url) {
    if (url == null || url == '') {
      return false;
    }

    var lastPart = url.substr(url.lastIndexOf('.') + 1);

    if (lastPart === "pdf" || lastPart === "doc" || lastPart === "docx" || lastPart === "xls") {
      return true;
    } else {
      return false;
    }
  }

  determineContact() {
    return "";
  }

  determineContactType() {
    return "";
  }

  determineSearchString(metatag) {
    let addtionalString = "";
    for (let index = 0; index < metatag.div.length; index++) {
      const element = metatag.div[index];
      if (element.attributes != null && element.attributes.class != null && element.attributes.class == "featured-category") {
        addtionalString = addtionalString + element.text + ' ';
      }
    }

    if (addtionalString != null && addtionalString != "") {
      return metatag.pstring = metatag.pstring + addtionalString;
    } else {
      return metatag.pstring;
    }
  }

  determineIsCralwerBlocked(metaTags) {
    if (this.stringService.substringExists(metaTags.pstring, "Cloudflare")) {
      return true;
    } else {
      return false;
    }
  }

  getHost(url) {
    var el = document.createElement('a');
    el.href = url;
    console.log(el)
    return el.hostname;
  }


  addCategory(categories, value, display) {
    categories.push({
      'display': display,
      'value': value
    });
    return categories;
  }

  determineDescriptionString(metatag){

    if(this.stringService.substringExists(metatag.url, "/material/")){
      for (let index = 0; index < metatag.div.length; index++) {
        const element = metatag.div[index];
        if (element.attributes != null && element.attributes.class != null && element.attributes.class == "material-description") {
          return element.text;
        } 
      }
    } else {
        let length = metatag.p.length;
        if(length < 5){
          length = metatag.p.length;
        } else {
          length = 5;
        }
        let additionalString = "";
        for (let index = 0; index < length; index++) {
          const element = metatag.p[index];
          if (element.text != null && element.text != "" && element.text != "Dein Warenkorb ist leer.") {
            additionalString = additionalString + element.text + ' ';
          }
        }
        return additionalString;
    }
  }

  determineImagesource(metatag){
    for (let index = 0; index < metatag.img.length; index++) {
      const element = metatag.img[index];
      if (element.attributes != null && element.attributes.alt != null && metatag.h1 != null && metatag.h1[0] != null && metatag.h1[0].text != null  &&element.attributes.title == metatag.h1[0].text) {
          let url =  element.attributes.src; 
          if(url == null){
            return "https://eduki.com/static/media/eduki-logo.e9c23d49.png";
          }  else {
            return url;
          }
      }
    }
  }
}
