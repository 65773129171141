import { Router, ActivatedRoute } from '@angular/router';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as alertFunctions from '../data/sweet-alerts';
import { GeneralDataService } from '../firebase-services/generalData.service';
import { SearchListItem } from '../models/searchlistitem.model';
declare let require: any;

const algoliasearch = require("algoliasearch");

const client = algoliasearch('3C2ZW0LB29', '1f387c8c1ba57738370c32395d4a7351');
const index = client.initIndex('searchentry');


@Injectable()
export class AlgoliaService {

  constructor(private translate: TranslateService, public generaldataservice: GeneralDataService) {

  }

  translateValue(input) {
    let value: any;
    this.translate.get(input).subscribe((res: string) => {
      value = res;
    });
    return value;
  }

  updateIndex() {
    let objects = []
    this.generaldataservice.getAllDatas("searchlist", true).then(res => {
      res.map(a => {
        let data = a.payload.doc.data()
        data.objectID = data.id;
        if(data.type == "" || data.type == null){
          data.type = "Entry";
        } 
        if(data.objectID != "" && data.objectID != null){
          objects.push(data)
        }        
      })
      console.log(objects)
       index
        .saveObjects(objects)
        .then(({ objectIDs }) => {
          console.log(objectIDs);
        })
        .catch(err => {
          console.log(err);
        }); 
    })

  }

  addIndex(object) {
    index
      .saveObject(object)
      .then(({ objectIDs }) => {
        console.log(objectIDs);
      })
      .catch(err => {
        console.log(err);
      });
  }



  search(query, page) {
    return new Promise<any>((resolve, reject) => {
      index
        .search(query, {
          "getRankingInfo": true,
          "analytics": false,
          "enableABTest": false,
          "hitsPerPage": 10,
          "attributesToRetrieve": "*",
          "attributesToSnippet": "*:20",
          "snippetEllipsisText": "…",
          "responseFields": "*",
          "explain": "*",
          "page": page,
          "facets": [
            "*"
          ]
        })
        .then(function (responses) {
          // Response from Algolia:
          // https://www.algolia.com/doc/api-reference/api-methods/search/#response-format
          resolve(responses) ;

        })
        .catch(err => {
          console.log(err);
        });;
    })
  }









}

// For the default version
