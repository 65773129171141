import { Router, ActivatedRoute } from '@angular/router';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as alertFunctions from '../data/sweet-alerts';
import { Reward } from '../models/reward.model';
import { GeneralDataService } from '../firebase-services/generalData.service';
import { StringService } from './string.service';

@Injectable()
export class VideoPortalService {


  constructor(private translate: TranslateService, public generalDataService: GeneralDataService, public stringService: StringService) {
  
  }

  getEmbadedVideoHtml(url){
    if (url != null && url != "" && this.stringService.substringExists(url, 'youtube') != true && this.stringService.substringExists(url, 'youtu.be') != true && this.stringService.substringExists(url, 'vimeo') != true) {
      return this.getEmbadedHTML(url);
    } else if (this.stringService.substringExists(url, 'youtube') == true || this.stringService.substringExists(url, 'youtu.be') == true) {
      return this.getEmbadedYoutubeLink(this.getYTVideoId(url))
    } else if (this.stringService.substringExists(url, 'vimeo') == true) {
      return this.getEmbadedVimeoLink(this.getVimeoId(url));
    }
  }

  getEmbadedHTML(url){
    return "<iframe width='560' height='315' src='" + url + "' title='Video player' frameborder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' allowfullscreen></iframe>"
  }

  getEmbadedYoutubeLink(id){
    return "<iframe width='315' height='315' src='https://www.youtube.com/embed/" + id +"' title='Video player' frameborder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' allowfullscreen></iframe>"
  }

  getEmbadedVimeoLink(id){
    return "<iframe src='https://player.vimeo.com/video/" + id + "' width='640' height='360' frameborder='0' allow='autoplay; fullscreen; picture-in-picture' allowfullscreen></iframe>"
  }

  getYTVideoId(url) {
    var r, rx = /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/;
    r = url.match(rx);
    return r[1];
  }

  getVimeoId(url) {
    var r, rx = /(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^\/]*)\/videos\/|album\/(?:\d+)\/video\/|video\/|)(\d+)(?:[a-zA-Z0-9_\-]+)?/i;
    r = url.match(rx);
    return r[1];
  }

  determineVideoImage(url) {
    if (url != null && url != "") {
      return url;
    } else if (this.stringService.substringExists(url, 'youtube') == true) {
      return 'https://img.youtube.com/vi/' + this.getYTVideoId(url) + '/0.jpg';
    } else if (this.stringService.substringExists(url, 'vimeo') == true) {
      //return this.httpService.getData("https://vimeo.com/api/oembed.json?url=https://vimeo.com/" + this.getVimeoId(entry.videoUrl)).then(res => {
      //console.log(res, res.thumbnail_url)
      //return "https://i.vimeocdn.com/video/1133573547_295x166";
      //return res.thumbnail_url;
      //})
      return "https://www.horizont.net/news/media/18/Vimeo-171771.png"
    }
  }

}
