import { Router, ActivatedRoute } from '@angular/router';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as alertFunctions from '../data/sweet-alerts';
import { SearchListItem } from '../models/searchlistitem.model';
import { EduScore } from '../models/eduscore.model';
import { search } from 'core-js/fn/symbol';
import { StringService } from './string.service';
import { isGeneratedFile } from '@angular/compiler/src/aot/util';
import { MetatagHttpService } from '../http-services/metatag-http.service';
import { MetaTagEdutagService } from '../metatag-services/metatagedutag.service';
import { ContentPagesModule } from 'app/pages/content-pages/content-pages.module';
import { MetaTagZumDeService } from '../metatag-services/metatagezumde.service';
import { MetaTagEdukiService } from '../metatag-services/metatageduki.service';

@Injectable()
export class SearchItemConverterService {


  constructor(private translate: TranslateService, public stringService: StringService, public metaTagServcie: MetatagHttpService, 
    public metaTagEduTagService: MetaTagEdutagService, public metaTagZumDeService: MetaTagZumDeService, public metaTagEdukiService: MetaTagEdukiService ) {

  }

  convertMetaTagsToSearchItemForLink(metaTags, url, inputSource, additionalSearchString) {
    return new Promise<any>((resolve, reject) => {
      //console.log(this.stringService.htmlToText(metaTags.html));
      //console.log(metaTags.html);
      if (metaTags == null) {
        this.metaTagServcie.getCrawlerByElements(this.getHost(url), "article", "iframe", "p", "div").then(metaTagRes => {
          if(metaTagRes != null){
            resolve(this.convertItemFor(metaTagRes, url, inputSource, additionalSearchString));
          } else  {
            // Wenn der Host trptzdem kein Ergebnis liefert
            this.metaTagServcie.getMetaTags(url).then(res => {
              resolve(this.convertItemFor(res, url, inputSource, additionalSearchString));
            })
          }
        })
      } else {
        resolve(this.convertItemFor(metaTags, url, inputSource, additionalSearchString));
      }
    })
  }

  convertItemFor(metaTags, url, inputSource, additionalSearchString){
    if(this.stringService.substringExists(metaTags.url, "edutags.de") == true){
      return this.metaTagEduTagService.convertItem(metaTags, url, inputSource, additionalSearchString)
    } else if(this.stringService.substringExists(metaTags.url, "zum.de") == true){
      return this.metaTagZumDeService.convertItem(metaTags, url, inputSource, additionalSearchString)
    } else if(this.stringService.substringExists(metaTags.url, "eduki") == true){
      return this.metaTagEdukiService.convertItem(metaTags, url, inputSource, additionalSearchString)
    } else {
      return this.convertItem(metaTags, url, inputSource, additionalSearchString)
    }

  }

  convertItem(metaTags, url, inputSource, additionalSearchString) {

    if (metaTags == null) {
      return;
    }

    let searchItem = new SearchListItem();
    searchItem.url = url;

    searchItem.videoUrls = this.getVideoUrls(metaTags.iframe)
    searchItem.title = this.determineTitle(metaTags)
    searchItem.description = this.determineDescription(metaTags);
    searchItem.type = this.determineType(metaTags, url, metaTags.host);
    searchItem.eduScore = new EduScore();
    searchItem.imageUrl = metaTags.imageUrl;
    searchItem.inputSource = inputSource;
    searchItem.name = this.determineTitle(metaTags)
    searchItem.searchDescription = this.determineSearchDescription(metaTags);
    searchItem.searchString = this.determineSearchString(metaTags);
    if(additionalSearchString != null && additionalSearchString != ""){
      searchItem.searchString = searchItem.searchString + " " + additionalSearchString;
    }
    searchItem.status = 'PUBLISHED';
    searchItem.createdAt = new Date();
    searchItem.contact = this.determineContact();
    searchItem.contactType = this.determineContactType();
    searchItem.countOfRatings = 0;
    searchItem.currentRating = 0;
    searchItem.host = metaTags.host;
    searchItem.crawlerBlocked = this.determineIsCralwerBlocked(metaTags);
    searchItem.categories = this.determineCategories(metaTags);

    console.log("Search-Item:", searchItem);

    return searchItem;
  }

  determineTitle(metaTags){
    if(metaTags.title != null &&  metaTags.title != ""){
      return  metaTags.title;
    } else {
      if (metaTags.h1 != null && metaTags.h1[0] != null && metaTags.h1[0] != [] && metaTags.h1[0].text != null && metaTags.h1[0].text != "") {
        return metaTags.h1[0].text;
      } else {
        return metaTags.host;
      }
    }
  }

  determineDescription(metaTags) {
    if (metaTags.description != null && metaTags.description != '') {
      return metaTags.description;
    } else {
      if (metaTags.p != null && metaTags.p[0] != null && metaTags.p[0] != [] && metaTags.p[0].text != null && metaTags.p[0].text != "") {
        return metaTags.p[0].text;
      } else {
        return "Die Seite hat " + metaTags.url + " hat keine Meta-Tags definiert. Aber trotzdem gute Inhalte. Wir werden versuchen mit den Seitenbetreibern in Kontakt zu treten, um die Beschreibung zu verbessern."
      }
    }
  }

  determineSearchDescription(metaTags) {
    if (metaTags.description != null && metaTags.description != '') {
      return metaTags.description;
    } else {
      if (metaTags.p != null && metaTags.p[0] != null && metaTags.p[0] != [] && metaTags.p[0].text != null && metaTags.p[0].text != "") {
        return metaTags.p[0].text;
      } else {
        return "Die Seite hat " + metaTags.url + " hat keine Meta-Tags definiert. Aber trotzdem gute Inhalte. Wir werden versuchen mit den Seitenbetreibern in Kontakt zu treten, um die Beschreibung zu verbessern."
      }
    }
  }


  getVideoUrls(iframes) {
    let urls = [];
    if (iframes != null) {
      for (let index = 0; index < iframes.length; index++) {
        const element = iframes[index];
        if (element.attributes.class == "video-embed" || element.attributes.allowfullscreen != null) {
          urls.push(element.attributes.src);
        }
      }
      return urls;
    }
  }

  determineType(metaTags, url, host) {
    if (this.isFile(url)) {
      return 'File';
    } else if (this.stringService.substringExists(host, 'twitter') || this.stringService.substringExists(host, 'facebook') || this.stringService.substringExists(host, 'linkedin') || this.stringService.substringExists(host, 'xing')) {
      return 'Account';
    } else if (this.stringService.substringExists(host, 'youtube') && this.stringService.substringExists(host, 'channel')) {
      return 'Account';
    } else if (this.stringService.substringExists(host, 'youtube') || this.stringService.substringExists(host, 'vimeo')) {
      return 'Video';
    } else if (this.stringService.substringExists(host, 'youtube')) {
      return 'Video';
    } else {
      return 'Entry';
    }
  }

  isFile(url) {
    if (url == null || url == '') {
      return false;
    }

    var lastPart = url.substr(url.lastIndexOf('.') + 1);

    if (lastPart === "pdf" || lastPart === "doc" || lastPart === "docx" || lastPart === "xls") {
      return true;
    } else {
      return false;
    }
  }

  determineContact() {
    return "";
  }

  determineContactType() {
    return "";
  }

  determineSearchString(metaTags) {
    if (this.stringService.substringExists(metaTags.pstring, "Cloudflare")) {
      return metaTags.pstring = metaTags.title + ' ' + metaTags.description;
    } else {
      return metaTags.pstring;
    }
  }

  determineIsCralwerBlocked(metaTags) {
    if (this.stringService.substringExists(metaTags.pstring, "Cloudflare")) {
      return true;
    } else {
      return false;
    }
  }

  getHost(url) {
    var el = document.createElement('a');
    el.href = url;
    console.log(el)
    return el.hostname;
  }

  determineCategories(metatags) {
    let categories = [];

  if (this.stringService.substringExists(metatags.url, "/oer") == true) {
      categories = this.addCategory(categories, 'OER', 'OER')
    }
    return categories;
  }

  addCategory(categories, value, display) {
    categories.push({
      'display': display,
      'value': value
    });
    return categories;
  }
}
