import { Router, ActivatedRoute } from '@angular/router';
import { Injectable } from '@angular/core';
import * as firebase from 'firebase/app';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { i18nService } from '../services/i18n.service';
import { map, catchError } from 'rxjs/operators';


//https://firebase.google.com/docs/auth/web/manage-users

@Injectable()
export class GeneralDataService {
  token: string;
  public loggedInState: firebase.User;
  public loggedInUid: string;

  uploadPercent: Observable<number>;
  downloadURL: Observable<string>;

  public online$: Observable<boolean>;

  constructor(private router: Router, private route: ActivatedRoute, public afs: AngularFirestore, public i18n: i18nService) {

  }

  getcollection(collection, i18n){
    if(i18n == true){
      return  this.i18n.getI18NCollection(collection, this.i18n.getLanguage())
    } else {
      return collection;
    }
  }


  updateData(collection, id, data, i18n) {
    return new Promise<any>((resolve, reject) => {
      this.afs.collection(this.getcollection(collection, i18n)).doc(id).set(JSON.parse(JSON.stringify(data)))
        .then(
          res => resolve(res),
          err => reject(err)
        )
    })
  }

  getData(collection, id, i18n) {
    return new Promise<any>((resolve, reject) => {
      this.afs.collection(this.getcollection(collection, i18n)).doc(id).snapshotChanges()
        .subscribe(snapshots => {
          resolve(snapshots)
        }), err => {
          reject(err);
        }
    })
  }

  getDatasForWithLimit(collection, field, operator, value, orderedBy, sequence, limit, i18n) {
    // asc, desc , == , !=, <,>
    return new Promise<any>((resolve, reject) => {
      this.afs.collection(this.getcollection(collection, i18n), ref => ref.where(field, operator, value).orderBy(orderedBy, sequence).limit(limit))
        .snapshotChanges()
        .subscribe(snapshots => {
          resolve(snapshots)
        }), err => {
          reject(err);
        }
    })
  }

  getDatasForWithOrder(collection, field, operator, value, orderedBy, sequence, i18n) {
    // asc, desc , == , !=, <,>
    return new Promise<any>((resolve, reject) => {
      this.afs.collection(this.getcollection(collection, i18n), ref => ref.where(field, operator, value).orderBy(orderedBy, sequence))
        .snapshotChanges()
        .subscribe(snapshots => {
          resolve(snapshots)
        }), err => {
          reject(err);
        }
    })
  }

  getDatasForSingleClient(collection, field, operator, value, i18n) {
    // asc, desc , == , !=, <,>
    return new Promise<any>((resolve, reject) => {
      this.afs.collection(this.getcollection(collection, i18n), ref => ref.where(field, operator, value))
        .snapshotChanges()
        .subscribe(snapshots => {
          resolve(snapshots)
        }), err => {
          reject(err);
        }
    })
  }


  getDatasFor(collection, field, operator, value, i18n) {
    // asc, desc , == , !=, <,>
    return new Promise<any>((resolve, reject) => {
      this.afs.collection(this.getcollection(collection, i18n), ref => ref.where(field, operator, value))
        .snapshotChanges()
        .subscribe(snapshots => {
          resolve(snapshots)
        }), err => {
          reject(err);
        }
    })
  }

  getDatasForValueChange(collection, field, operator, value, i18n) {
    // asc, desc , == , !=, <,>
    return new Promise<any>((resolve, reject) => {
      this.afs.collection(this.getcollection(collection, i18n), ref => ref.where(field, operator, value))
        .valueChanges({ idField: 'id' })
        .subscribe(snapshots => {
          resolve(snapshots)
        }), err => {
          reject(err);
        }
    })
  }

  getAllDatasWithoutClient(collection) {
    return new Promise<any>((resolve, reject) => {
      this.afs.collection(collection)
        .snapshotChanges()
        .subscribe(snapshots => {
          resolve(snapshots)
        }), err => {
          reject(err);
        }
    })
  }

  getAllDatasWithoutClientValueChange(collection) {
    return new Promise<any>((resolve, reject) => {
      this.afs.collection(collection)
        .valueChanges({ idField: 'id' })
        .subscribe(snapshots => {
          resolve(snapshots)
        }), err => {
          reject(err);
        }
    })
  }

  getMappedDataFor(collection, field, operator, value, i18n, objectName){
    return this.afs.collection(this.getcollection(collection, i18n), ref => ref.where(field, operator, value))
    .snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as typeof objectName
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
    );
  }


  getAllDatas(collection, i18n){
    return new Promise<any>((resolve, reject) => {
    
      this.getAllDatasWithoutClient(this.getcollection(collection, i18n)).then(res => {
        resolve(res)
      })
  
    })
  }

  getAllDatasValueChange(collection, i18n){
    return new Promise<any>((resolve, reject) => {
      this.getAllDatasWithoutClientValueChange(this.getcollection(collection, i18n)).then(res => {
        resolve(res)
      })
  
    })
  }
  

  getMyDatas(collection, userId, i18n) {
    return new Promise<any>((resolve, reject) => {
      this.afs.collection(this.getcollection(collection, i18n), ref => ref.where('creatorId', '==', userId))
        .snapshotChanges()
        .subscribe(snapshots => {
          resolve(snapshots);
        })
    })
  }

  setData(collection, data, i18n:boolean) {
    return new Promise<any>((resolve, reject) => {
      this.afs.collection(this.getcollection(collection, i18n)).add(JSON.parse(JSON.stringify(data)))
        .then(
          res => resolve(res),
          err => reject(err)
        )
    })
  }


  deleteData(collection, id, i18n){
    return new Promise<any>((resolve, reject) => {
      this.afs.collection(this.getcollection(collection, i18n)).doc(id).delete()
        .then(
          res => resolve(res),
          err => reject(err)
        )
    })
  }

  deleteCollection(collection, id, i18n){
    return new Promise<any>((resolve, reject) => {
      this.afs.collection(this.getcollection(collection, i18n)).doc(id).delete()
        .then(
          res => resolve(res),
          err => reject(err)
        )
    })
  }

  uploadImageWithName(collection, id, imageURI, name) {
    return new Promise<any>((resolve, reject) => {
      let storageRef = firebase.storage().ref();
      let imageRef = storageRef.child(collection).child(id).child( this.randomInteger(1, 9999) + '_' + name)
      this.encodeImageUri(imageURI, function (image64) {
        imageRef.putString(image64, 'data_url')
          .then(snapshot => {
            resolve(snapshot.ref.getDownloadURL())
          }, err => {
            reject(err);
          })
      })
    })
  }

  uploadImage(collection, id, imageURI) {
    return new Promise<any>((resolve, reject) => {
      let storageRef = firebase.storage().ref();
      let imageRef = storageRef.child(collection).child(id).child('images').child('header');
      this.encodeImageUri(imageURI, function (image64) {
        imageRef.putString(image64, 'data_url')
          .then(snapshot => {
            resolve(snapshot.ref.getDownloadURL())
          }, err => {
            reject(err);
          })
      })
    })
  }

  randomInteger(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  uploadFile(collection, id, file) {
    return new Promise<any>((resolve, reject) => {
      console.log(file, id, collection)
      let storageRef = firebase.storage().ref('/'+collection+'/' + id + '/files/' +  this.randomInteger(1, 9999) + '_' + file.name);
      //let fileRef = storageRef.child('Datas').child(id).child('files');

      storageRef.put(file).then(snapshot =>{
        resolve(snapshot.ref.getDownloadURL())
      }, err => {
        reject(err);
      })
    })
  }

  encodeImageUri(imageUri, callback) {
    var c = document.createElement('canvas');
    var ctx = c.getContext("2d");
    var img = new Image();
    img.onload = function () {
      var aux: any = this;
      c.width = aux.width;
      c.height = aux.height;
      ctx.drawImage(img, 0, 0);
      var dataURL = c.toDataURL("image/jpeg");
      callback(dataURL);
    };
    img.src = imageUri;
  };
}
