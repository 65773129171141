import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { AngularFireModule } from "@angular/fire";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';

import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { AgmCoreModule } from "@agm/core";
import { ToastrModule } from "ngx-toastr";
import { DeviceDetectorModule } from 'ngx-device-detector';
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { NgxSpinnerModule } from 'ngx-spinner';
import { StoreModule } from "@ngrx/store";
import { SharedModule } from "./shared/shared.module";


import {
  PerfectScrollbarModule,
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface
} from 'ngx-perfect-scrollbar';

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { ContentLayoutComponent } from "./layouts/content/content-layout.component";
import { FullLayoutComponent } from "./layouts/full/full-layout.component";

import { AuthService } from "./shared/auth/auth.service";
import { AuthGuard } from "./shared/auth/auth-guard.service";
import { WINDOW_PROVIDERS } from './shared/services/window.service';
import { FaqService } from './shared/services/faq.service';
import { DragulaService } from 'ng2-dragula';
import { StorageService } from './shared/services/storage.service';
import { UserService } from './shared/services/user.service';
import { AddressService } from './shared/services/address.service';
import { MailService } from './shared/services/mail.service';
import { DeviceService } from './shared/services/device.service';
import { AlertService } from './shared/services/alert.service';
import { environment } from 'environments/environment';
import { AdminAuthGuard } from './shared/auth/admin-auth-guard.service';

import { ShareButtonsConfig } from 'ngx-sharebuttons';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { i18nService } from "./shared/services/i18n.service";
import { DBService } from "./shared/services/db.service";
import { GeneralDataService } from "./shared/firebase-services/generalData.service";
import { ConsoleService } from "@ng-select/ng-select/lib/console.service";
import { ConstantsService } from "./shared/services/constants.service";
import { ArrayService } from "./shared/services/array.service";
import { AlgoliaService } from "./shared/services/alogia.service";
import { GlobalDataService } from "./shared/services/globalData.service";
import { RewardService } from "./shared/services/reward.service";
import { LoginService } from "./shared/services/login.service";
import { PromiseService } from "./shared/services/promise.service";
import { MetatagHttpService } from "./shared/http-services/metatag-http.service";
import { StringService } from "./shared/services/string.service";
import { JitsiService } from "./shared/services/jitsi.service";
import { JwtService } from "./shared/services/jwt.service";
import { ErrorService } from "./shared/services/error.service";
import { ScriptService } from "./shared/services/script.service";
import { RatingService } from "./shared/services/rating.service";
import { MyPhpHttpService } from "./shared/http-services/myphp-http.service";
import { SearchItemConverterService } from "./shared/services/searchitemconverter.service";
import { HttpService } from "./shared/http-services/http.service";
import { MetaTagEdutagService } from "./shared/metatag-services/metatagedutag.service";
import { HtmlService } from "./shared/services/html.service";
import { MetaTagZumDeService } from "./shared/metatag-services/metatagezumde.service";
import { MetaTagEdukiService } from "./shared/metatag-services/metatageduki.service";
import { EduScoreService } from "./shared/services/eduscore.service";
import { TaskBoardService } from "./pages/full-pages/taskboard/taskboard.service";
import { PromptService } from "./shared/services/prompt.service";
import { CopyService } from "./shared/services/copy.service";
import { QuillService } from "./shared/services/quill.service";
import { ImageService } from "./shared/services/image.service";
import { UploadService } from "./shared/services/upload.service";
import { VideoPortalService } from "./shared/services/videoportal.service";
import { ToastService } from "./shared/services/toast.service";
import { ResizableDirective } from "./shared/directives/resizable.directive";
import { MyTaskBoardService } from "./pages/full-pages/my-taskboards/my-taskboard.service";
import { ToolService } from "./shared/services/tool.service";
import { MiroService } from "./shared/services/miro.service";
import { SearchToolService } from "./pages/full-pages/taskboard/search-tool-modal/search-tool.service";
import { ConnectionService } from "./shared/services/connection.service";
import { TaskboardGuiService } from "./pages/full-pages/taskboard/taskboard-gui.service";
import { TaskboardCopyService } from "./pages/full-pages/taskboard/taskboard-copy.service";
import { ColorPickerModule } from 'ngx-color-picker';
import { RouterStateSnapshot } from "@angular/router";






var firebaseConfig = {
  apiKey: "YOUR_API_KEY", //YOUR_API_KEY
  authDomain: "YOUR_AUTH_DOMAIN", //YOUR_AUTH_DOMAIN
  databaseURL: "YOUR_DATABASE_URL", //YOUR_DATABASE_URL
  projectId: "YOUR_PROJECT_ID", //YOUR_PROJECT_ID
  storageBucket: "YOUR_STORAGE_BUCKET", //YOUR_STORAGE_BUCKET
  messagingSenderId: "YOUR_MESSAGING_SENDER_ID", //YOUR_MESSAGING_SENDER_ID
  appId: "YOUR_APP_ID", //YOUR_APP_ID
  measurementId: "YOUR_MEASUREMENT_ID" //YOUR_MEASUREMENT_ID
};


const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelPropagation: false
};

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
  declarations: [AppComponent, FullLayoutComponent, ContentLayoutComponent],
  imports: [
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    AngularFireAnalyticsModule,
    ToastrModule.forRoot(),
    NgbModule,

    NgxSpinnerModule,
    DeviceDetectorModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    AgmCoreModule.forRoot({
      apiKey: "YOUR_GOOGLE_MAP_API_KEY"
    }),
    PerfectScrollbarModule,
    ShareButtonsModule,
    ShareIconsModule,
    ColorPickerModule
  ],
  providers: [
    AuthService,
    FaqService,
    StorageService,
    UserService,
    AddressService,
    MailService,
    DeviceService,
    AuthGuard,
    AdminAuthGuard,
    DragulaService,
    VideoPortalService,
    LoginService,
    AlertService,
    ToolService,
    MiroService,
    ToastService,
    PromiseService,
    i18nService,
    StringService,
    UploadService,
    DBService,
    ArrayService,
    JitsiService,
    JwtService,
    HtmlService,
    ConnectionService,
    ErrorService,
    TaskBoardService,
    MyTaskBoardService,
    ImageService,
    SearchItemConverterService,
    MetaTagEdutagService,
    QuillService,
    MetaTagEdukiService,
    ScriptService,
    CopyService,
    PromptService,
    MetaTagZumDeService,
    GeneralDataService,
    AlgoliaService,
    EduScoreService,
    SearchToolService,
    GlobalDataService,
    HttpService,
    MyPhpHttpService,
    RatingService,
    MetatagHttpService,
    RewardService,
    TaskboardCopyService,
    ConstantsService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG },
    WINDOW_PROVIDERS
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
