import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { JwtService } from './jwt.service';
import { StringService } from './string.service';
import { ErrorService } from './error.service';
import { DomSanitizer, SafeHtml, SafeResourceUrl } from '@angular/platform-browser';
import { UserService } from './user.service';
import { StorageService } from './storage.service';
import { ScriptService } from './script.service';
import { Router } from '@angular/router';

declare const miroBoardsPicker: any;
declare const JitsiMeetExternalAPI: any;


@Injectable()
export class JitsiService {

  user: any;
  domain: any;

  constructor(public http: HttpClient, public jwtService: JwtService, public stringService: StringService, public errorService: ErrorService, private sanitizer: DomSanitizer,
    public storageService: StorageService, public scriptService: ScriptService, public router: Router) {
    this.user = this.storageService.getUser();
    this.loadJitMeetScripts();
    //this.load8x8Script();
  }

  loadJitMeetScripts() {
    this.scriptService.load("https://meet.jit.si/external_api.js");
    this.domain = "meet.jit.si"

  }

  load8x8Script() {
    this.scriptService.load("https://8x8.vc/libs/external_api.min.js");
    this.domain = "8x8.vc"
  }

  async startMeet(roomName) {

    return new Promise<any>((resolve, reject) => {
      //this.loadJitMeetScripts();
      let expiresIn = Math.floor(Date.now() / 1000) + (60 * 60 * 24)
      let header = {
        //alg: 'RS256',
        kid: environment.jitsiConfig.apiKey,
      }
      this.jwtService.setPayloadForJitsi(header, "RS256", "chat", expiresIn, "jitsi", (Math.floor(Date.now() / 1000) - 1000000), environment.jitsiConfig.tenant, "*", this.user.id, this.user.profile.displayName, this.user.profile.photoURL, this.user.profile.email, true, false, false);
      let key = this.jwtService.getTokenSynchron(environment.jitsiConfig.secret);

   
       const domain = this.domain;
       const options = {
         roomName: environment.jitsiConfig.tenant + "/" + roomName,
         width: 500,
         height: 500,
         //jwt: key,
         //jwt: key,
         parentNode: document.getElementById("jitsi")
         //windowRef.document.getElementById("#jitsi")
         //document.querySelector('#container')
       };
       const api = new JitsiMeetExternalAPI(domain, options);
       resolve(api)
     
    })

  }

  async startMeetAnonym(roomName) {

    return new Promise<any>((resolve, reject) => {
      //this.loadJitMeetScripts();
      let expiresIn = Math.floor(Date.now() / 1000) + (60 * 60 * 24)
      let header = {
        //alg: 'RS256',
        kid: environment.jitsiConfig.apiKey,
      }
      this.jwtService.setPayloadForJitsi(header, "RS256", "chat", expiresIn, "jitsi", (Math.floor(Date.now() / 1000) - 1000000), environment.jitsiConfig.tenant, "*", roomName, roomName, "", "", true, false, false);
      let key = this.jwtService.getTokenSynchron(environment.jitsiConfig.secret);

   
       const domain = this.domain;
       const options = {
         roomName: environment.jitsiConfig.tenant + "/" + roomName,
         width: 1024,
         height: 800,
         //jwt: key,
         //jwt: key,
         parentNode: document.getElementById("jitsi")
         //windowRef.document.getElementById("#jitsi")
         //document.querySelector('#container')
       };
       const api = new JitsiMeetExternalAPI(domain, options);
       resolve(api)
     
    })

  }

  getMeetingApi(roomName, containerName){
    return new Promise<any>((resolve, reject) => { 
       const domain = this.domain;
       const options = {
         roomName: environment.jitsiConfig.tenant + "/" + roomName,
         width: 600,
         height: 600,
         //jwt: key,
         parentNode: document.getElementById(containerName)
         //windowRef.document.getElementById("#jitsi")
         //document.querySelector('#container')
       };
       const api = new JitsiMeetExternalAPI(domain, options);
       resolve(api)
    })
  }

  startVideoWindow(id){
    if(id == null || id == ""){
      return;
    }

    let url = window.location.host;
    if(url == "localhost:4200"){
      url = "http://localhost:4200";
    } else{
      url = ""
    }
    const windowRef = window.open(
      url + '/pages/video?id=' + id,
      '_blank',
      `width=720,height=750,left=200,top=200,menubar=0,toolbar=0,location=0,personalbar=0,status=0`
    )
  }

}
