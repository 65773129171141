import { Router, ActivatedRoute } from '@angular/router';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as alertFunctions from '../../shared/data/sweet-alerts';

@Injectable()
export class ImageService {


  constructor(private translate: TranslateService) {
  
  }

  getImageUrl(width, heigh, id){
    return 'https://picsum.photos/seed/header' + id + '/' + width + '/' +  heigh;
  }


  getRandomImage(id, width, heigh){
    return 'https://picsum.photos/seed/random' + id + '/' + width + '/' +  heigh;
  }

}
