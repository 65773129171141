import { Component, ViewContainerRef, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { GlobalDataService } from './shared/services/globalData.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {

    subscription: Subscription;

    constructor(private router: Router, public translate: TranslateService, public globalService: GlobalDataService) {
        translate.setDefaultLang(this.translate.getBrowserLang());
        this.globalService.initCategories()
    }

    ngOnInit() {
       this.initCookieMessage()
        this.subscription = this.router.events
            .pipe(
                filter(event => event instanceof NavigationEnd)
            )
            .subscribe(() => window.scrollTo(0, 0));
    }


    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    initCookieMessage(){
        /* let cc = window as any;
        cc.cookieconsent.initialise({
          palette: {
            popup: {
              background: "#164969"
            },
            button: {
              background: "#ffe000",
              text: "#164969"
            }
          },
          theme: "classic",
          content: {
            message: "Diese Website verwendet Cookies, um Ihnen die bestmögliche Nutzung unserer Website zu gewährleisten.",
            dismiss: "Ok",
            link: "Lerne mehr",
            href: "https://www.aeb.com/intl-de/datenschutz.php"
          }
        }); */
    }


}