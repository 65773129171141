import { ChangeDetectorRef, Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/Rx'; 




//https://firebase.google.com/docs/auth/web/manage-users

@Injectable()
export class ConnectionService {

  public online$: Observable<boolean>;

  constructor() {
    this.online$ = Observable.merge(
      Observable.of(navigator.onLine),
      Observable.fromEvent(window, 'online').mapTo(true),
      Observable.fromEvent(window, 'offline').mapTo(false)
    )
  }


   
}
