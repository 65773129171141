import { Router, ActivatedRoute } from '@angular/router';
import { Injectable } from '@angular/core';
import * as firebase from 'firebase/app';



//https://firebase.google.com/docs/auth/web/manage-users

@Injectable()
export class StorageService {
  token: string;
  public loggedInState: firebase.User;
  public loggedInUid: string;

  constructor() {
    //this.clearSessionStorage()
  }

  setUser(user) {   
    if(this.getRememberMe() == 'X'){
      localStorage.setItem("User", JSON.stringify(user));
    } else {
      sessionStorage.setItem("User", JSON.stringify(user));
    }
  }

  getUser(): any {
    let user;
    
    if(this.getRememberMe() == 'X'){
      user = JSON.parse(localStorage.getItem("User"));
    } else {
      user = JSON.parse(sessionStorage.getItem("User"));
    }
    if(user && user.profile && !user.profile.photoURL){
      user.profile.photoURL = "assets/img/portrait/avatars/avatar-08.png"
    }
    return user;
  }

  getLanguageToUse() {
    return sessionStorage.getItem("Language");
  }

  setLanguageToUse(language) {
    sessionStorage.setItem("Language", language);
  }

  getRememberMe(){
    return localStorage.getItem("RememberMe");
  }

  setRememberMe(rememberMe){
    if(rememberMe == true){
      rememberMe = 'X';
    }else {
      rememberMe = '-';
    }
    localStorage.setItem("RememberMe",rememberMe);
  }

  clearSessionStorage(){
    sessionStorage.clear();
  }

  setTemplateConf(templateConf){
    localStorage.setItem("template", JSON.stringify(templateConf));
  }

  getTemplateConf(){
    return JSON.parse(localStorage.getItem("template"));
  }

  getBooleanForValue(value){
    if(value == 'X'){
      return true;
    }else {
      return false
    }
  }

  getValueFoBoolean(value){
    if(value == true){
      return  'X';
    }else {
      return  '-';
    }
  }

  getStartedTour(){
    return this.getBooleanForValue(localStorage.getItem("tour"));
  }

  setStartedTour(tour){
    localStorage.setItem("tour", this.getValueFoBoolean(tour));
  }






}
