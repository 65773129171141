
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GeneralDataService } from '../firebase-services/generalData.service';
import { UploadFile } from '../models/uploadfile.model';
import * as firebase from 'firebase/app';
import { reject } from 'core-js/fn/promise';


@Injectable()
export class UploadService {

  files: UploadFile[] = [];

  constructor(private translate: TranslateService, public generalDataService: GeneralDataService) {

  }

  addFile(file: UploadFile) {
    this.files.push(file)
  }

  getFiles() {
    return this.files;
  }

  uploadFiles(collection, id, files, deleteBase64) {
    return new Promise<any>((resolve, reject) => {
      let promises = []
      for (let index = 0; index < files.length; index++) {
        const element = files[index];
        if (element.url == null || element.url == "") {
          promises.push(this.uploadFile(collection, id, element, deleteBase64))
        }

      }
      Promise.all(promises).then(res => {
        for (let index = 0; index < res.length; index++) {
          const element = res[index];
        }
        resolve(res)
      })
    })
  }

  uploadFile(collection, id, file, deleteBase64) {
    return new Promise<any>((resolve, reject) => {
      let name = file.name;
      let type = file.type;
      let size = file.size;
      if (file != null && file != "") {
        this.generalDataService.uploadFile(collection, id, file).then(url => {
          let file = new UploadFile()
          file.url = url;
          file.name = name;
          file.size = size;
          file.type = type;
          if (deleteBase64 == true) {
            file.base64 = "";
          }
          resolve(file)
        }, err => {
          reject(err);
        })
      }
      else {
        resolve("")
      }
    })
  }

  uploadImages(collection, id, images, deleteBase64) {
    return new Promise<any>((resolve, reject) => {
      let promises = []
      console.log(images)
      for (let index = 0; index < images.length; index++) {
        const element = images[index];
        if (element.url == null || element.url == "") {
          promises.push(this.uploadImage(collection, id, element.base64, 'base64', element, deleteBase64))
        }
      }
      Promise.all(promises).then(res => {
        for (let index = 0; index < res.length; index++) {
          const element = res[index];
        }
        resolve(res)
      })
    })
  }

  uploadImage(collection, id, image, type, file, deleteBase64) {
    return new Promise<any>((resolve, reject) => {
      if (image != null && image != "") {
        if (type == 'base64') {
          this.generalDataService.uploadImageWithName(collection, id, image, file.name).then(imageUrl => {
            file.url = imageUrl
            if (deleteBase64 == true) {
              file.base64 = "";
            }
            resolve(file)
          }, err => {
            reject(err);
          })
        } else {
          resolve(image);
        }
      }
    })
  }

  deleteFiles(task) {
    if (task.files == null || task.files == []) {
      return;
    }
    for (let index = 0; index < task.files.length; index++) {
      const element = task.files[index];
      this.deleteFileByUrl(element.url)
    }
  }

  deleteFileByUrl(url) {
    let storageRef = firebase.storage().ref();
    try {
      storageRef.storage.refFromURL(url).delete();
    } catch (error) {
      console.log("Error", error)
    }
  }

  async getBase64FromUrl(url) {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      }
    });
  }

  getDownloadUrl(url) {
    let storageRef = firebase.storage().ref();

    try {
      return storageRef.storage.refFromURL(url).getDownloadURL();
    } catch (error) {
      return error
    }

  }
}
