import { Router, ActivatedRoute } from '@angular/router';
import { Injectable } from '@angular/core';
import * as firebase from 'firebase/app';
import { AuthService } from '../auth/auth.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { StorageService } from './storage.service';
import { AddressService } from './address.service';
import { Address } from '../models/adress.model';
import { UserProfile } from "../models/userProfile.model";
import { UserData } from "../models/user.model";
import { RewardService } from './reward.service';
import { ConstantsService } from './constants.service';
import { GlobalDataService } from './globalData.service';
import { AngularFireAuth } from '@angular/fire/auth';


//https://firebase.google.com/docs/auth/web/manage-users

@Injectable()
export class UserService {
  token: string;
  public loggedInState: firebase.User;
  public loggedInUid: string;
  user: any;

  private userProfile: UserProfile = new UserProfile();
  private userData: UserData = new UserData();

  constructor(private router: Router, private route: ActivatedRoute,
    private authService: AuthService, public afs: AngularFirestore, private storageService: StorageService, public afAuth: AngularFireAuth,
    private addressService: AddressService, public rewardService: RewardService, public constants: ConstantsService, public globalDataService: GlobalDataService) {

    this.user = this.storageService.getUser()
  }

  checkAdress() {
    return new Promise<any>((resolve, reject) => {
      this.user = this.storageService.getUser()
      this.addressService.getAddress(this.user.id, 'INVOICE').then(res => {
        let address = res.payload.data() as Address;
        if (address || address == null) {
          resolve(address)
        } else {
          reject(false)
        }
      })
    })
  }

  updateUserForFirebase(user, displayName, photoURL) {
    return new Promise<any>((resolve, reject) => {
      user.updateProfile({
        displayName: displayName,
        photoURL: photoURL,
      }).then(function () {
        resolve(true)
      }).catch(function (error) {
        error.message = "Error: Verification-Mail konnte nicht gesendet werden";
        reject(error)
      });
    })
  }

  logout() {
    this.authService.logout();
  }

  getPhotoUrl(id, i, j) {
    return 'https://picsum.photos/seed/' + id + '/' + i + '/' + j;
  }

  getHeaderUrl() {
    return 'https://picsum.photos/seed/header' + this.user.id + '/1280/720'
  }

  count() {
    this.afs.collection('/user').valueChanges()
      .subscribe(result => {
      })
  }

  updateUser(key, user) {
    return new Promise<any>((resolve, reject) => {
      //if (value.name) { value.nameToSearch = value.name.toLowerCase(); }
      this.storageService.setUser(user)
      this.afs.collection('/user').doc(key).set(JSON.parse(JSON.stringify(user)))
        .then(
          res => resolve(res),
          err => reject(err)
        )
    })
  }

  getUserFor(field, operator, value, orderedBy, sequence, limit) {
    // asc, desc , == , !=, <,>
    return new Promise<any>((resolve, reject) => {
      this.afs.collection('/user', ref => ref.where(field, operator, value).orderBy(orderedBy, sequence).limit(limit))
        .snapshotChanges()
        .subscribe(snapshots => {
          resolve(snapshots)
        }), err => {
          reject(err);
        }
    })
  }


  getUser(key) {
    return new Promise<any>((resolve, reject) => {
      this.afs.collection('/user').doc(key).snapshotChanges()
        .subscribe(snapshots => {
          resolve(snapshots)
        }), err => {
          reject(err);
        }
    })
  }

  getUserByMail(mail) {
    return new Promise<any>((resolve, reject) => {
      this.afs.collection('/user', ref => ref.where("email", "==", mail)).snapshotChanges()
        .subscribe(snapshots => {
          resolve(snapshots)
        }), err => {
          reject(err);
        }
    })
  }

  setUser(key, user) {
    return new Promise<any>((resolve, reject) => {
      this.afs.collection('/user').doc(key).set(JSON.parse(JSON.stringify(user)))
        .then(
          res => resolve(res),
          err => reject(err)
        )
    })
  }


  setProfile(key, profile) {
    return new Promise<any>((resolve, reject) => {
      this.afs.collection('/user').doc(key).collection('profile').add(JSON.parse(JSON.stringify(profile)))
        .then(
          res => resolve(res),
          err => reject(err)
        )
    })
  }

  getAccounts(key) {
    return new Promise<any>((resolve, reject) => {
      this.afs.collection('/user').doc(key).collection('/acoounts').snapshotChanges()
        .subscribe(snapshots => {
          resolve(snapshots)
        }), err => {
          reject(err);
        }
    })

  }

  addAccount(key, account) {
    return new Promise<any>((resolve, reject) => {
      this.afs.collection('/user').doc(key).collection('/accounts').add({
        id: account.id,
        created: account.created,
      })
        .then(
          res => resolve(res),
          err => reject(err)
        )
    })
  }

  buildUserData(user, operationType, isAnonymous, userName) {
    let email;
    if (operationType == "password") {
      email = user.email;
    } else if (isAnonymous) {
      email = user.uid + "@openinnovationcircle.com"
    } else {
      email = user.providerData[0].email
    }

    if (isAnonymous) {
      if (userName != null && userName != "") {
        this.userProfile.displayName = userName;
      } else {
        this.userProfile.displayName = user.uid;
      }

      this.userProfile.photoURL = 'https://picsum.photos/seed/' + user.uid + '/' + 300 + '/' + 300;
      this.userProfile.signInType = "Anonym";
    } else {
      this.userProfile.displayName = user.displayName;
      this.userProfile.photoURL = user.photoURL;
      this.userProfile.signInType = operationType;
    }
    if (this.userProfile.photoURL == null) {
      this.userProfile.photoURL = 'https://picsum.photos/seed/' + user.uid + '/' + 300 + '/' + 300;
    }
    if (this.userProfile.displayName == null) {
      this.userProfile.displayName = email;
    }


    this.userProfile.email = email;

    this.userProfile.emailVerified = user.emailVerified;
    this.userProfile.id = user.uid;

    this.userProfile.created = user.metadata.creationTime;
    this.userProfile.createdDate = Date.now();

    this.user = []
    this.user.level = 1;
    this.user.points = 0;
    this.user.rewards = [];
    this.user.rewards.push(this.rewardService.getFirstReward())

    this.userData.email = email;
    this.userData.profile = this.userProfile;
    this.userData.id = user.uid;
    this.userData.roles = []
    this.userData.roles.push(this.constants.c_userrole_user)
    return this.userData;
  }

  getRoles() {
    return new Promise<any>((resolve, reject) => {
      this.user = this.globalDataService.getUser();
      if (this.user == null) {
        let tmpUser = this.storageService.getUser();
        if(tmpUser == null){
          resolve([])
        }
        this.getUser(tmpUser.id).then(res => {
          this.globalDataService.setUser(res.payload.data());
          this.user = this.globalDataService.getUser();
          if(this.user == null ){
            resolve([])
          } else {
            resolve(this.user.roles);
          }
        
        })
      } else {
        resolve(this.user.roles);
      }
    });
  }

  isAuth(roleToNeed) {
    return new Promise<boolean>((resolve, reject) => {
      this.getRoles().then(roles => {
        if (roles.includes(roleToNeed) || roles.includes(this.constants.c_userrole_admin) || this.authService.rollIncludesRoles(roles, roleToNeed) == true) {
          resolve(true);
        } else {
          resolve(false);
        }
      })
    })
  }


  

  deletUser(userId) {
    return new Promise<any>((resolve, reject) => {
      this.afs.collection('/user').doc(userId).delete()
        .then(
          res => {
            resolve(res)
          },
          err => reject(err)
        )
    })
  }

}
