import { Router, ActivatedRoute } from '@angular/router';
import { Injectable } from '@angular/core';
import * as firebase from 'firebase/app';
import { AuthService } from '../auth/auth.service';
import { AngularFirestore } from '@angular/fire/firestore';


//https://firebase.google.com/docs/auth/web/manage-users

@Injectable()
export class AddressService {
  token: string;
  public loggedInState: firebase.User;
  public loggedInUid: string;

  constructor(private router: Router, private route: ActivatedRoute, private authService: AuthService, public afs: AngularFirestore) {
    

  }

  updateAddress(userId, addresstype, addressdata) {
    return new Promise<any>((resolve, reject) => {
      this.afs.collection('/address').doc(userId).collection('/type').doc(addresstype).set(JSON.parse(JSON.stringify(addressdata)))
        .then(
          res => resolve(res),
          err => reject(err)
        )
    })
  }

  getAddress(userId, addresstype){
    return new Promise<any>((resolve, reject) => {
      this.afs.collection('/address').doc(userId).collection('type').doc(addresstype).snapshotChanges()
        .subscribe(snapshots => {
          resolve(snapshots)
        }), err => {
          reject(err);
        }
    })
  }


  setAddress(userId, addresstype, addressdata){
    return new Promise<any>((resolve, reject) => {
      this.afs.collection('/address').doc(userId).collection('/type').doc(addresstype).set(JSON.parse(JSON.stringify(addressdata)))
        .then(
          res => resolve(res),
          err => reject(err)
        )
    })
  }

}
