import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import 'rxjs/add/operator/toPromise';
import { environment } from 'environments/environment';

import { HttpClientModule, HttpClient, HttpHeaders , HttpParams} from '@angular/common/http';

@Injectable()
export class HttpService {

  constructor(public http: HttpClient) { }

  getData(url) {
    return new Promise<any>((resolve, reject) => {
      //this.http.post(environment.api_endpoint + url, {  }).subscribe(
      this.http.get(url, {  }).subscribe(
        result => {
         resolve(result);
        },
        error => {
          reject(error);
        }
      );
    });
  }



}
