export class EduScore{
    eduglePoints:string;
    cumulatedEduScore: number;           //USER, EDUGLE
    innovationScore:number;
    accessScore: number;
    relevantScore: number;
    sustainabilityScore:number;
    benefitScore: number;
    innovationComment:number;
    accessComment: number;
    relevantComment: number;
    sustainabilityComment:number;
    benefitComment: number;
 }
    