
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { UserService } from './user.service';
import { Address } from '../models/adress.model';
import { PrefixNot } from '@angular/compiler';

@Injectable()

export class ErrorService {
  public c_error_firebase_allready_in_use = "auth/credential-already-in-use"
  public c_error = "Error"
  public c_canceled = "Cancel"

  constructor(public translate: TranslateService, public http: HttpClient) { }

  
  
  
}