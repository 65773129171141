import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import 'rxjs/add/operator/toPromise';
import { environment } from 'environments/environment';

import { HttpClientModule, HttpClient, HttpHeaders , HttpParams} from '@angular/common/http';

@Injectable()
export class MetatagHttpService {
  token: string;

  constructor(public http: HttpClient) { }

  getMetaTags(url){
    return new Promise<any>((resolve, reject) => {
      //let url = environment.api_endpoint + 'meta.php'
      let apiUrl = "/api/meta.php"
      this.http.post(apiUrl, {'url': url}).subscribe(
        result => {
         resolve(result);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  getCrawlerByElements(url, element1, element2, element3, element4){
    if(element1 == "" && element2 == "" &&  element3 == "" && element4 == ""){
      element1 = 'article';
      element2 = 'p'
    }

    return new Promise<any>((resolve, reject) => {
      //let url = environment.api_endpoint + 'meta.php'
      let apiUrl = "/api/crawlerClass.php"
      console.log(apiUrl, url)
      this.http.post(apiUrl, {'url': url, 'element1': element1, 'element2': element2, 'element3': element3, 'element4': element4,}).subscribe(
        result => {
          console.log(result)
         result = this.determineSearchText(result) 
         resolve(result);
        },
        error => {
          console.log(error)
          reject(error);
        }
      );
    });
  }

  determineSearchText(crawlerResult){

    if(crawlerResult == null){
      return; 
    }

    if(crawlerResult.p == null || crawlerResult.p == []){
      return;
    }

    crawlerResult.pstring = crawlerResult.url;

    for (let index = 0; index < crawlerResult.p.length; index++) {
      const element = crawlerResult.p[index];

      crawlerResult.pstring = crawlerResult.pstring + ' ' + element.text;
    }

    return crawlerResult;

  }

}
