import { Injectable } from '@angular/core';
import { MiroService } from './miro.service';

@Injectable({
  providedIn: 'root'
})
export class ToolService {

  constructor(public miroService: MiroService) { }

  getUrl(tool) {
    return new Promise<any>((resolve, reject) => {
      if (tool.host[0] == 'miro.com') {
        this.miroService.open().then(res => {
          resolve(res.viewLink);
        })
      }
    })
  }
}
