import { Component, OnInit, Input } from '@angular/core';
import { Board } from '../../my-taskboards/board.model';
import { TaskboardAuthService } from '../taskboard-auth.service';
import { TaskboardCardfunctionService } from '../taskboard-cardfunction.service';
import { Task } from '../taskboard.model';

@Component({
  selector: 'app-taskboard-viewer-modal',
  templateUrl: './taskboard-viewer-modal.component.html',
  styleUrls: ['./taskboard-viewer-modal.component.scss']
})
export class TaskboardViewerModalComponent implements OnInit {

  @Input() task: Task;
  @Input() board: Board;
  @Input() ah:string;

  constructor(public authService: TaskboardAuthService, public cardFunctionService: TaskboardCardfunctionService) { }

  ngOnInit(): void {
  }

  determineTaskMessage(html) {
    return html;
  }

  isAuthForChange(neededAuthority?) {
    return this.authService.isAuthForChange(this.board, this.ah, neededAuthority)
  }

}
