
import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';


//https://firebase.google.com/docs/auth/web/manage-users

@Injectable()
export class StringService {

  constructor( ) {
    
  }

  replace(string:string, stringToReplace: string, replaceWithString: string){
    return string.replace(stringToReplace, replaceWithString);
  }

  replaceAll(string:string, stringToReplace: string, replaceWithString: string){
    return string.split(stringToReplace).join(replaceWithString);
  }


  htmlToText(html){
      let text = html;
      text = text.replace(/\n/gi, "");
      text = text.replace(/<style([\s\S]*?)<\/style>/gi, "");
      text = text.replace(/<script([\s\S]*?)<\/script>/gi, "");
      text = text.replace(/<a.*?href="(.*?)[\?\"].*?>(.*?)<\/a.*?>/gi, " $2 $1 ");
      text = text.replace(/<\/div>/gi, "\n\n");
      text = text.replace(/<\/li>/gi, "\n");
      text = text.replace(/<li.*?>/gi, "  *  ");
      text = text.replace(/<\/ul>/gi, "\n\n");
      text = text.replace(/<\/p>/gi, "\n\n");
      text = text.replace(/<br\s*[\/]?>/gi, "\n");
      text = text.replace(/<[^>]+>/gi, "");
      text = text.replace(/^\s*/gim, "");
      text = text.replace(/ ,/gi, ",");
      text = text.replace(/ +/gi, " ");
      text = text.replace(/\n+/gi, "\n\n");
      return text;
  }


  addStartEnd(string, start, end){
    return start + string + end;
  }

  findSubstring(str, start, end){
    var startindex = str.indexOf(start);
    var endindex = str.indexOf(end, startindex);
    if (startindex !=-1 && endindex !=-1 &&  endindex  > startindex )
    return str.substring(startindex , endindex + end.length)
  }

  substringExists(str, substring){
    if(str == null){
      return false;
    }
    
    if(str.includes(substring)){
      return true;
    } else {
      return false;
    }
  }

  
}
