import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    //let isAuth = this.authService.isAuthenticated(state.url);
    console.log(state.url)
    if (state.url.includes("/pages/search") ){
      return true
    }
    else {
      this.router.navigate(['/pages/search']);
    }
      
   
  }
}
