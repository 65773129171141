
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Reward } from '../models/reward.model';
import { GeneralDataService } from '../firebase-services/generalData.service';

@Injectable()
export class PromiseService {

  promises: any = []
  datas: any = []


  constructor(private translate: TranslateService, public generalDataService: GeneralDataService) {

  }

  init(){
    this.promises= []
  }

  push(data) {
    this.promises.push(data)
  }

  pushArray(array){
    for (let index = 0; index < array.length; index++) {
      const element = array[index];
      this.push(element)
    }
  }

  getDeleteResult(){
    return new Promise<any>(async (resolve, reject) => {
      Promise.all(this.promises).then(res => {
        resolve(res)
      })
    })
  }

  getResultWithoutData(){
    return new Promise<any>(async (resolve, reject) => {
      Promise.all(this.promises).then(res => {
        resolve(res)
      })
    })
  }

  getResult() {
    return new Promise<any>(async (resolve, reject) => {
      Promise.all(this.promises).then(res => {
        res.map((a: any[]) => {
          console.log(a)
          if(res == null || res == undefined){
            resolve([]);
          } else{
            for (let y = 0; y < a.length; y++) {
              const element = a[y];
              let data = element.payload.doc.data()
              this.datas.push(data);
            }
            resolve(this.datas);
          }
        })
      })
    })
  }

  getResultArrays() {
    return new Promise<any>(async (resolve, reject) => {
      Promise.all(this.promises).then(res => {
        let myArray = []
        for (let index = 0; index < res.length; index++) {
          let element: any;
          element = res[index];
          for (let index = 0; index < element.length; index++) {
            const element2 = element[index];
            myArray.push(element2)
          }
        }
        resolve(myArray)
      })
    })
  }


  getPromiseForMethod(query){
    let promises = [];
    promises.push(query)
    return new Promise<any>(async (resolve, reject) => {
      Promise.all(this.promises).then(res => {
        resolve(res)
      })
    })
  }


}
