import { Router, ActivatedRoute } from '@angular/router';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as alertFunctions from '../../shared/data/sweet-alerts';

@Injectable()
export class AlertService {


  constructor(private translate: TranslateService) {
  
  }

  translateValue(input){
    let value:any;
    this.translate.get(input).subscribe((res: string) => {
        value = res;
    });
    return value;
}

info(title, message, icon){
  alertFunctions.TypeInfo(this.translateValue(title), this.translateValue(message), icon);
}



}
