
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { UserService } from './user.service';
import { Address } from '../models/adress.model';
import { PrefixNot } from '@angular/compiler';

@Injectable()

export class MailService {
  title: any;
  subtitle: any;
  buttonText: any;

  constructor(public translate: TranslateService, public http: HttpClient, private userService: UserService) { }

  sendMail(email, title, message) {

      return new Promise<any>((resolve, reject) => {

        this.http.post(environment.endpoint + '/api/mail/sendmail.php',
          {
            'email': email, 'title': title, 'message': message
          }).subscribe(
            result => {
              resolve(result);
            },
            error => {
              reject(error);
            }
          );
      });
  }

  sendMailForWallet(wallet, email, title, message){
    this.userService.getUser
  }

  sendMailTemplate(title:string, templateName: string, email, data1, data2, data3, data4, data5) {
    return new Promise<any>((resolve, reject) => {
      this.http.post(environment.endpoint + '/api/mail/' + templateName + '.php', { "email": email, "title": title, "data1": data1, "data2": data2, "data3": data3, "data4": data4, "data5": data5}).subscribe(
        result => {
         resolve(result);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  
    sendInvoice(flag_mwst: boolean, buyerAddress: Address, buyerMail: string, senderAddress: Address, senderMail:string, value: string, currency: string, ispayed: boolean, invoicenumber:string, product: string, productcount: string, invoicetype: string, paymentmethod: string, additionalMail:string, date:string) {
      var render = "F";
      var address_type = "I";
      //var company = "5b2546bb00dc91.35025288";
      //flag_mwst = true //Partyrazzi itself
      
      return new Promise<any>((resolve, reject) => {
        this.http.post(environment.api_endpoint + '/invoice/saveinvoice.php', {'render': render, 'address_type': address_type, 'flag_mwst': flag_mwst, 'value': value, 'currency': currency, 'is_payed': ispayed, 'invoicenumber': invoicenumber, 'product': product, 'productcount': productcount, 'invoicetype': invoicetype, 
                                                                               'paymentmethod': paymentmethod, 'additionalMail': additionalMail, 'invoicedate': date,
                                                                               'senderForename': senderAddress.forename, 'senderSurename': senderAddress.surename, 'senderCompany': senderAddress.company, 'senderStreet': senderAddress.street, 'senderPostalcode': senderAddress.postalcode, 'senderCity': senderAddress.city, 'senderCountry': senderAddress.country, 'senderMail': senderMail,  
                                                                               'buyerForename': buyerAddress.forename, 'buyerSurename': buyerAddress.surename, 'buyerCompany': buyerAddress.company, 'buyerStreet': buyerAddress.street, 'buyerPostalcode': buyerAddress.postalcode, 'buyerCity': buyerAddress.city, 'buyerCountry': buyerAddress.country, 'buyerMail': buyerMail}).subscribe(
          result => {
           resolve(result);
          },
          error => {
            reject(error);
          }
        );
      });
    }
  
  
}